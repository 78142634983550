import React, { useEffect } from 'react'
import {
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CheckboxProps as MuiCheckboxProps,
  FormHelperText,
  makeStyles,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { ErrorResponse } from '../api'

export type SelectCheckboxProps = {
  label: string
  value: string
} & MuiCheckboxProps

type MultiSelectCheckboxProps = {
  label: string
  name: string
  inputRef: React.Ref<HTMLInputElement>
  /** APIレスポンスで返ってきたエラー */
  fetchErrors?: ErrorResponse | null
  /**
   * APIレスポンスで返ってくる場合の項目名
   * 未指定の場合、nameが使われる
   */
  fetchName?: string
  checkboxes: SelectCheckboxProps[]
  defaultValue?: string[]
  className?: string
}

const useStyle = makeStyles({
  accordion: {
    marginLeft: '4px !important',
    marginRight: '4px !important',
  },
})

export const MultiSelectCheckbox: React.FC<MultiSelectCheckboxProps> = (
  props,
) => {
  const {
    label,
    name,
    checkboxes,
    inputRef,
    fetchErrors,
    fetchName,
    defaultValue,
  } = props
  const style = useStyle()

  const [value, setValue] = React.useState<string[]>(defaultValue || [])
  const handleChange = (
    event: React.ChangeEvent<{
      name?: string
      value: string
      checked: boolean
    }>,
  ) => {
    if (event.target.checked) {
      setValue([...value, event.target.value])
    } else {
      setValue(value.filter((item) => item !== event.target.value))
    }
  }

  useEffect(() => {
    setValue(defaultValue || [])
  }, [defaultValue])

  const fetchMessages =
    fetchErrors?.errors
      ?.filter((e) => e.field === (fetchName || name))
      ?.map((e) => e.message) || []

  return (
    <Accordion
      className={`${style.accordion} ${props.className}`}
      data-test={`Accordion-${name}`}
      variant="outlined"
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography>{label}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl component="fieldset" error={fetchMessages.length > 0}>
          <FormGroup row>
            {checkboxes?.map((x) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={value.includes(x.value)}
                    value={x.value}
                    onChange={handleChange}
                    name={name}
                    inputRef={inputRef}
                  />
                }
                label={x.label}
                key={x.label}
              />
            ))}

            {/* 生成されるチェックボックスが１つのみの場合でもonSubmitで受け取る際の型がStringの配列になるよう、ダミーのチェックボックスを配置する */}
            {checkboxes?.length === 1 && (
              <Checkbox
                checked={false}
                name={name}
                inputRef={inputRef}
                style={{ display: 'none' }}
              />
            )}
          </FormGroup>
          {fetchMessages.map((message) => (
            <FormHelperText key={label}>{message}</FormHelperText>
          ))}
        </FormControl>
      </AccordionDetails>
    </Accordion>
  )
}
