// 元請企業サービス利用マスタ提供APIをfetchするHook

import { ErrorResponse, useAxios } from '.'
import { useApiGlobalErrorHandler } from '../hooks'
import { GlobalErrorHandlerOption } from './types'

export type PrimeCompanyServicesResponse = Service[]

export type Service = {
  serviceId: number
  serviceName: string
}

export type PrimeCompanyServicesApiParam = {
  prime: boolean
  partnerCompanyId: string
  projectId: string
}

type UsePrimeCompanyServicesApi = {
  data: PrimeCompanyServicesResponse | undefined
  error: ErrorResponse | null
  loading: boolean
  doFetch: (params: PrimeCompanyServicesApiParam) => void
}

/**
 * 元請企業サービス利用マスタ提供 API Hook
 * @param param
 * @param option
 * @returns
 */
export const usePrimeCompanyServicesApi = (
  param: PrimeCompanyServicesApiParam,
  option?: GlobalErrorHandlerOption,
): UsePrimeCompanyServicesApi => {
  const url = getUrl(param)
  const { data, error, loading, fetchData } =
    useAxios<PrimeCompanyServicesResponse>({
      method: 'GET',
      url,
    })

  if (option?.use ?? true) {
    useApiGlobalErrorHandler(error, option)
  }

  const doFetch = (param: PrimeCompanyServicesApiParam) => {
    const url = getUrl(param)
    fetchData({
      method: 'GET',
      url,
    })
  }

  return { data, error, loading, doFetch }
}

const getUrl = (param: PrimeCompanyServicesApiParam) => {
  const { prime, partnerCompanyId, projectId } = param

  return prime
    ? `/prime/projects/${projectId}/partnerCompanies/${partnerCompanyId}/primeCompanyServices`
    : `/partnerCompanies/${partnerCompanyId}/projects/${projectId}/primeCompanyServices`
}
