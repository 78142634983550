// axiosを使用してDPP BFF APIを呼び出すHook

import { useState, useEffect } from 'react'
import axios, { AxiosRequestConfig } from 'axios'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../hooks'

axios.defaults.baseURL =
  process.env.REACT_APP_BFF_API_URL || 'http://localhost:4000'

const client = axios.create({
  withCredentials: true,
  headers: {
    'x-api-key': process.env.REACT_APP_BFF_API_KEY,
  },
})

type Error = {
  field: string
  value: unknown
  message: string
}

export type ErrorResponse = {
  status: number
  code: string
  errors: Error[]
  message: string
}

const defaultOption = {
  manual: false,
}

const BFF_ERROR_STATUS = [400, 401, 403, 404, 409, 500, 504]

export const useAxios = <TResponse>(
  config: AxiosRequestConfig,
  option: { manual: boolean } = defaultOption,
): {
  data: TResponse | undefined
  error: ErrorResponse | null
  loading: boolean
  fetchData: (params: AxiosRequestConfig) => Promise<void>
  clearError: () => void
} => {
  const [data, setResponse] = useState<TResponse | undefined>(undefined)
  const [error, setError] = useState<ErrorResponse | null>(null)
  const [loading, setLoading] = useState(!option?.manual)
  const history = useHistory()
  const { user } = useAuth()

  const fetchData = async (params: AxiosRequestConfig) => {
    setLoading(true)
    setError(null)

    // POSTの場合、ヘッダにCSRFトークンを設定する
    const { headers, ...restParams } = params
    const csrfHeader =
      params.method?.toUpperCase() === 'POST'
        ? { 'x-csrf-token': user?.csrfToken || '' }
        : {}

    try {
      const { data } = await client.request<TResponse>({
        headers: {
          ...csrfHeader,
          ...headers,
        },
        ...restParams,
      })
      setResponse(data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const { response } = error
        // ダミーログイン画面の初期表示した時点ではhistoryが無い。そのための回避処理。
        if (history) {
          const path = history.location.pathname + history.location.search

          const isUnExpectedHttpStatus =
            response?.status && !BFF_ERROR_STATUS.includes(response.status)
          if (isUnExpectedHttpStatus) {
            history.replace(path, { errorStatusCode: 500 })

            return
          }

          if (response?.status === 403) {
            history.replace(path, { errorStatusCode: 404 })

            return
          }
        }

        setResponse(undefined)
        setError({
          status: response?.status || 500,
          code: response?.data.code || 'UNHANDLED_ERROR',
          errors: response?.data.errors || [],
          message: response?.data.message || '予期せぬエラーが発生しました',
        })
      }

      return
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!option?.manual) {
      fetchData(config)
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {
      setResponse(undefined)
    }
  }, [])

  const clearError = () => {
    setError(null)
  }

  return { data, error, loading, fetchData, clearError }
}
