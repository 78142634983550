export const useCurrentUrl = (): {
  getCurrentUrl: () => string | null
  setCurrentUrl: (url: string) => void
  removeCurrentUrl: () => void
} => {
  const getCurrentUrl = () => {
    return sessionStorage.getItem('current_url')
  }

  const setCurrentUrl = (url: string) => {
    sessionStorage.setItem('current_url', url)
  }

  const removeCurrentUrl = () => {
    sessionStorage.removeItem('current_url')
  }

  return { getCurrentUrl, setCurrentUrl, removeCurrentUrl }
}
