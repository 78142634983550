// ダミーログインAPIをfetchするHook

import { ErrorResponse, useAxios } from '.'
import { User } from '../providers/AuthProvider'

export type DummyLoginData = {
  mcdpId: string
}

export type DummyLoginResponse = User

type UseDummyLoginApi = {
  data: DummyLoginResponse | undefined
  error: ErrorResponse | null
  loading: boolean
  doFetch: (param: DummyLoginData) => void
}
export const useDummyLoginApi = (): UseDummyLoginApi => {
  const { data, error, loading, fetchData } = useAxios<DummyLoginResponse>(
    {},
    { manual: true },
  )

  const doFetch = (param: DummyLoginData) => {
    fetchData({
      method: 'GET',
      url: `/auth/login/dummy/${param.mcdpId}`,
    })
  }

  return { data, error, loading, doFetch }
}
