import { makeStyles } from '@material-ui/core'
import { Button, ButtonProps } from './Button'

const useStyles = makeStyles(() => ({
  button: {
    margin: 8,
  },
}))

export type StyledButtonProps = ButtonProps

export const StyledButton: React.FC<StyledButtonProps> = (props) => {
  const { children, className, ...rest } = props
  const classes = useStyles()

  return (
    <Button
      variant="outlined"
      color="secondary"
      size="medium"
      className={`${className} ${classes.button}`}
      {...rest}
    >
      {children}
    </Button>
  )
}
