import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

/**
 * 履歴のクエリストリングを管理するコンテキストのデータ型
 * 外部からはクエリストリングには直接アクセスはせず、操作メソッドを介して取得する。
 */
export type HistoryQueryContextType = {
  findByPath: (url: string) => string
}

/**
 * 履歴のクエリストリングを管理するコンテキスト
 */
export const HistoryQueryContext = React.createContext<HistoryQueryContextType>(
  {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    findByPath: (url: string) => {
      return ''
    },
  },
)

type Props = {
  children: React.ReactNode;
};

/**
 * 履歴のクエリストリングを管理するコンテキストのプロバイダー
 */
export const HistoryQueryProvider: React.FC<Props> = (props) => {
  const [historyQuery] = useState<{ [key: string]: string }>({})
  const location = useLocation()
  useEffect(() => {
    historyQuery[location.pathname] = location.search
  }, [location.search])

  const findByPath = (url: string) => {
    return url + (historyQuery[url] ?? '')
  }

  return (
    <HistoryQueryContext.Provider value={{ findByPath }}>
      {props.children}
    </HistoryQueryContext.Provider>
  )
}
