import React from 'react'
import { ProjectServiceIcon } from '..'
import { PartnerCompanyProjectResponse } from '../../../api'

/**
 * プロジェクト関連サービスアイコン
 * @param props
 * @returns
 */
export const ProjectServiceIcons: React.FC<{
  services?: PartnerCompanyProjectResponse['relatedServices']
}> = (props) => {
  const { services } = props

  if (!services?.length) {
    return null
  }

  return (
    <>
      {services?.map((service, index) => (
        <span
          style={{ display: 'inline-block', fontSize: 0 }}
          data-test={`projectServices-${service.projectServiceId}`}
          key={service.projectServiceId + index}
        >
          <ProjectServiceIcon
            projectServiceId={service.projectServiceId}
            projectServiceName={service.projectServiceName}
          ></ProjectServiceIcon>
        </span>
      ))}
    </>
  )
}
