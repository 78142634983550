import {
  Grid,
  IconButton,
  makeStyles,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useQueryState } from 'react-router-use-location-state'
import {
  ContentTitle,
  ErrorMessageContainer,
  InlineContainer,
  SearchButton,
  StyledTextField,
  StickyTable,
} from '../../../components'
import { useAuth, useTitle } from '../../../hooks'
import { ListPageTemplate as Template } from '../../template'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import React, { useEffect } from 'react'
import {
  HelpdeskCompaniesResponse,
  MANAGER_TYPE,
  useHelpdeskCompaniesApi,
} from '../../../api'
import { Forbidden } from '../../error/Forbidden'
import { useThrottledCallback } from 'use-debounce'

const NO_DATA_MESSAGE = 'データが見つかりませんでした。'

/**
 * 検索条件項目
 */
type HelpdeskCompaniesSearchCondition = Partial<{
  companyId: string
  companyName: string
}>

const useStyles = makeStyles(() => ({
  textField: {
    width: '270px',
  },
}))

/**
 * 企業検索画面
 */
export const HelpdeskCompaniesPage: React.FC = () => {
  const PAGE_TITLE = '企業検索'
  useTitle(PAGE_TITLE)

  const { user } = useAuth()

  /** ログインユーザーの管理者区分がヘルプデスク または MCDP運用担当 */
  const isHelpDeskOrMcdpUser =
    user?.managerType === MANAGER_TYPE.HELP_DESK ||
    user?.managerType === MANAGER_TYPE.MCDP_USER

  // 権限がない場合エラーとする為画面個別で判別する（初期表示時は検索を実行しない為）
  if (!isHelpDeskOrMcdpUser) {
    return <Forbidden />
  }

  // 検索条件をクエリストリングから取得する
  const [companyIdQuery, setCompanyIdQuery] = useQueryState('companyId', '')
  const [companyNameQuery, setCompanyNameQuery] = useQueryState(
    'companyName',
    '',
  )

  const classes = useStyles()

  const { doFetch, data, error, loading } = useHelpdeskCompaniesApi(
    {
      companyId: companyIdQuery,
      companyName: companyNameQuery,
    },
    {
      handleValidateFields: ['companyId', 'companyName'],
      initialFetch: !!companyIdQuery || !!companyNameQuery,
    },
  )

  /**
   * 検索ボタンクリック(submit)ハンドラ
   * @param data 検索条件
   */
  const onSubmit: SubmitHandler<HelpdeskCompaniesSearchCondition> =
    useThrottledCallback(
      (data) => {
        doFetch({
          companyId: data.companyId,
          companyName: data.companyName,
        })
        setCompanyIdQuery(data.companyId || '')
        setCompanyNameQuery(data.companyName || '')
      },
      1000,
      { trailing: false },
    )

  const {
    handleSubmit,
    register,
    errors: formErrors,
  } = useForm<HelpdeskCompaniesSearchCondition>({
    criteriaMode: 'all',
    reValidateMode: 'onSubmit',
  })

  // エラーメッセージ
  const [errorMessages, setErrorMessages] = React.useState<string[]>([])
  useEffect(() => {
    const messages = []

    // 検索結果なし
    if (data?.companies.length === 0) {
      messages.push(NO_DATA_MESSAGE)
    }

    // APIエラー
    const helpdeskCompaniesError =
      error?.errors
        .filter((error) => !error.field)
        .map((error) => error.message)
        .join('') || error?.message
    if (helpdeskCompaniesError) {
      messages.push(helpdeskCompaniesError)
    }

    setErrorMessages(messages)
  }, [data, error])

  return (
    <Template.Container>
      <Template.Body>
        <Template.Header>
          <div>
            <ContentTitle title={PAGE_TITLE} />
          </div>
          <InlineContainer>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container alignItems="flex-start">
                <Grid item xs>
                  <StyledTextField
                    name="companyId"
                    label="企業ID"
                    inputRef={register}
                    formErrors={formErrors}
                    fetchErrors={error}
                    defaultValue={companyIdQuery}
                    className={classes.textField}
                    maxLength={10}
                  />

                  <StyledTextField
                    name="companyName"
                    label="企業名"
                    inputRef={register}
                    formErrors={formErrors}
                    fetchErrors={error}
                    defaultValue={companyNameQuery}
                    className={classes.textField}
                    maxLength={100}
                  />
                </Grid>
                <Grid item>
                  <SearchButton />
                </Grid>
              </Grid>
            </form>
          </InlineContainer>
        </Template.Header>

        <Template.ErrorContent loading={loading}>
          <ErrorMessageContainer messages={errorMessages} />
        </Template.ErrorContent>

        <Template.Content
          loading={loading}
          hidden={!data?.companies.length || errorMessages.length > 0}
        >
          <CompanyTable companies={data?.companies} />
        </Template.Content>
      </Template.Body>
    </Template.Container>
  )
}

const useStyle = makeStyles({
  tableCell: {
    minWidth: '60px',
  },
  tableRow: {
    cursor: 'pointer',
  },
})

/**
 * 企業一覧テーブル
 * @param props
 * @returns
 */
const CompanyTable: React.FC<{
  companies?: HelpdeskCompaniesResponse['companies']
}> = (props) => {
  const { companies } = props
  const history = useHistory()
  const classes = useStyle()

  return (
    <StickyTable data-test="partnerCompanies-table">
      <TableHead>
        <TableRow>
          <TableCell
            key="companyId"
            align="center"
            className={classes.tableCell}
          >
            企業ID
          </TableCell>
          <TableCell key="companyName" align="center">
            企業名称
          </TableCell>
          <TableCell
            key="companyTypeName"
            align="center"
            className={classes.tableCell}
          >
            企業区分
          </TableCell>
          <TableCell key="link" align="center"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {companies?.map((row) => {
          return (
            <TableRow
              hover
              tabIndex={-1}
              key={row.companyId}
              className={row.hasLink ? classes.tableRow : ''}
              onClick={() => {
                if (row.hasLink) {
                  history.push(`/partnerCompanies/${row.companyId}/projects`)
                }
              }}
            >
              <TableCell
                data-test="companyId-table-cell"
                align="center"
                className={classes.tableCell}
              >
                {row.companyId}
              </TableCell>
              <TableCell data-test="companyName-table-cell" align="left">
                {row.companyName}
              </TableCell>
              <TableCell
                data-test="companyTypeName-table-cell"
                align="center"
                className={classes.tableCell}
              >
                {row.companyTypeName}
              </TableCell>
              <TableCell
                data-test="hasLink-table-cell"
                align="right"
                size="small"
              >
                {row.hasLink && (
                  <IconButton aria-label="invited users">
                    <ArrowForwardIosIcon fontSize="default" color="secondary" />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </StickyTable>
  )
}
