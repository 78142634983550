import { ErrorResponse, useAxios } from '.'
import { User } from '../providers/AuthProvider'

export type AuthCallBackAPIParams = {
  code: string
  scope: string
}

export const useAuthCallbackApi = (
  params: AuthCallBackAPIParams,
): {
  data: User | undefined
  error: ErrorResponse | null
  loading: boolean
} => {
  const { data, error, loading } = useAxios<User>({
    method: 'GET',
    url: `/auth/callback`,
    params,
  })

  return { data, error, loading }
}
