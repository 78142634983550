import { ErrorResponse, useAxios } from '.'
import { useApiGlobalErrorHandler } from '../hooks'
import { GlobalErrorHandlerOption } from './types'
import { querystring } from './util'

export type InvitedProjectsPrimeCompaniesResponse = PrimeCompany[]

export type PrimeCompany = {
  primeCompanyId: string
  primeCompanyName: string
}

type InvitedProjectsPrimeCompaniesApiParam = {
  partnerCompanyId: string
  limit?: number
  offset?: number
}

type UseInvitedProjectsPrimeCompaniesApi = {
  data: InvitedProjectsPrimeCompaniesResponse | undefined
  error: ErrorResponse | null
  loading: boolean
  doFetch: (params: InvitedProjectsPrimeCompaniesApiParam) => void
}

/**
 * 招待プロジェクト元請企業リスト提供API Hook
 * @param param
 * @param option
 * @returns
 */
export const useInvitedProjectsPrimeCompaniesApi = (
  param: InvitedProjectsPrimeCompaniesApiParam,
  option?: GlobalErrorHandlerOption,
): UseInvitedProjectsPrimeCompaniesApi => {
  const { partnerCompanyId, ...queryParams } = param
  const qs = querystring.stringify(queryParams)
  const { data, error, loading, fetchData } =
    useAxios<InvitedProjectsPrimeCompaniesResponse>({
      method: 'GET',
      url: `/partnerCompanies/${partnerCompanyId}/invitedProjectsPrimeCompanies?${qs}`,
    })

  if (option?.use ?? true) {
    useApiGlobalErrorHandler(error, option)
  }

  const doFetch = (param: InvitedProjectsPrimeCompaniesApiParam) => {
    const { partnerCompanyId, ...queryParams } = param
    const qs = querystring.stringify(queryParams)
    fetchData({
      method: 'GET',
      url: `/partnerCompanies/${partnerCompanyId}/invitedProjectsPrimeCompanies?${qs}`,
    })
  }

  return { data, error, loading, doFetch }
}
