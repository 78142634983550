import { useHistory } from 'react-router-dom'
import { StyledButton, StyledButtonProps } from './StyledButton'

type HistoryBackButtonProps = {
  url?: string
} & StyledButtonProps

export const HistoryBackButton: React.FC<HistoryBackButtonProps> = (props) => {
  const history = useHistory()
  const { url, ...rest } = props

  return (
    <StyledButton
      onClick={() => {
        if (url) {
          history.push(url)
        } else {
          history.goBack()
        }
      }}
      data-test="history-back"
      {...rest}
    >
      戻る
    </StyledButton>
  )
}
