import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  infoContent: {
    display: 'inline-flex',
    marginTop: '8px',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
}))

type Props = {
  children?: React.ReactNode;
};

export const ContentInfo: React.FC<Props> = (props) => {
  const classes = useStyles(props)

  return (
    <div className={classes.infoContent} data-test="content-info">
      {props.children}
    </div>
  )
}
