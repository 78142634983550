import { makeStyles, Typography } from '@material-ui/core'
import { useTitle } from '../../hooks'

const useStyles = makeStyles(() => ({
  page: {
    textAlign: 'center',
    marginTop: '25vh',
  },
}))

export const Logout: React.FC = () => {
  useTitle('ログアウト')
  const classes = useStyles()

  return (
    <div className={classes.page}>
      <Typography variant="h6">ご利用ありがとうございました。</Typography>
    </div>
  )
}
