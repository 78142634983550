// 協力会社ユーザー認可（登録・更新）APIをPOSTで実行するHook

import { ErrorResponse, useAxios } from '.'
import { useApiGlobalErrorHandler } from '../hooks'
import { GlobalErrorHandlerOption } from './types'

type PartnerCompanyUser = {
  mcdpId: string
  enabled: boolean
  updateDatetime?: string | null
}

export type PartnerCompanyUsersPostApiParam = {
  prime: boolean
  partnerCompanyId: string
  projectId: string
  companyPositionId: string
  partnerCompanyUsers: PartnerCompanyUser[]
}

type UsePartnerCompanyUsersPostApi = {
  data: PartnerCompanyUser[] | undefined
  error: ErrorResponse | null
  loading: boolean
  doPost: (params: PartnerCompanyUsersPostApiParam) => void
  clearError: () => void
}

/**
 * 協力会社ユーザー認可（登録・更新）API
 * @param option
 * @returns
 */
export const usePartnerCompanyUsersPostApi = (
  option?: GlobalErrorHandlerOption,
): UsePartnerCompanyUsersPostApi => {
  const { data, error, loading, fetchData, clearError } = useAxios<
    PartnerCompanyUser[]
  >({}, { manual: true })

  if (option?.use ?? true) {
    useApiGlobalErrorHandler(error, option)
  }

  const doPost = (param: PartnerCompanyUsersPostApiParam) => {
    const { partnerCompanyUsers } = param
    const url = getUrl(param)
    fetchData({
      method: 'POST',
      url,
      data: partnerCompanyUsers,
    })
  }

  return { data, error, loading, doPost, clearError }
}

const getUrl = (param: PartnerCompanyUsersPostApiParam) => {
  const { prime, partnerCompanyId, projectId, companyPositionId } = param

  return prime
    ? `/prime/projects/${projectId}/partnerCompanies/${partnerCompanyId}/companyPositions/${companyPositionId}/users`
    : `/partnerCompanies/${partnerCompanyId}/projects/${projectId}/companyPositions/${companyPositionId}/users`
}
