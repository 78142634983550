import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { ErrorResponse } from '../api'

/**
 * API のグローバルエラーハンドリング Hookのオプション
 */
export type UseApiGlobalErrorHandlerOption = {
  /**
   * バリデーションエラー表示をするフィールド
   *
   * このパラメータに指定しなかったフィールドで API バリデーションエラーが発生した場合、404画面にリダイレクトされる。画面でバリデーションエラー表示をしない不正なフィールドに対する 400 エラーが発生した際は、404画面にリダイレクトさせる。主に、ブラウザのパスパラメータで指定された ID に対して APIリクエスト時にバリデーションエラーが発生した場合に該当する。
   *
   */
  handleValidateFields?: string[]

  /**
   * システムエラー（500, 504）のメッセージ
   *
   * デフォルトの振る舞いは以下のとおり
   * 500エラーの場合、BFF から返却されるメッセージをそのまま表示する
   * 504エラーの場合、「予期せぬエラーが発生しました。画面を再表示してください。」を表示する。
   */
  message?: {
    [key in 500 | 504]?: string
  }

  /**
   * サーバサイド 404 エラーに対して、画面単位で 404 ページに遷移させるか
   *
   * デフォルト true
   */
  notFoundRedirect?: boolean
}

const MESSAGE_504 = '予期せぬエラーが発生しました。画面を再表示してください。'

/**x
 * API のグローバルエラーハンドリング Hook
 */
export const useApiGlobalErrorHandler = (
  error: ErrorResponse | null,
  option?: UseApiGlobalErrorHandlerOption,
): void => {
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (!error) {
      return
    }

    const { errors, status, message } = error

    const msg = {
      500: option?.message?.[500] ?? message,
      504: option?.message?.[504] ?? MESSAGE_504,
    }

    const currentUrl = history.location.pathname + history.location.search

    if (status === 400) {
      // 画面にバリデーションエラー表示をするフィールド以外のエラーが含まれていた場合、404画面にリダイレクトさせる。
      const hasUnSupportedFieldErrors = errors.some(
        ({ field }) => field && !option?.handleValidateFields?.includes(field),
      )
      if (hasUnSupportedFieldErrors) {
        history.replace(currentUrl, { errorStatusCode: 404 })
      }
    }
    if (status === 404) {
      // サーバサイドからの 404 に対して、画面単位で 404 ページに遷移させる
      if (option?.notFoundRedirect ?? true) {
        history.replace(currentUrl, { errorStatusCode: 404 })
      }
    }

    if (status === 500 || status === 504) {
      enqueueSnackbar(msg[status], {
        variant: 'error',
        anchorOrigin: { horizontal: 'left', vertical: 'top' },
      })
    }
  }, [error])
}
