import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from '@material-ui/core'

export type ButtonProps = MuiButtonProps

export const Button: React.FC<ButtonProps> = (props) => {
  return <MuiButton {...props}>{props.children}</MuiButton>
}
