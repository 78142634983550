// 協力会社ユーザーリスト提供APIをfetchするHook

import { ErrorResponse, useAxios } from '.'
import { useApiGlobalErrorHandler } from '../hooks'
import { GlobalErrorHandlerOption } from './types'
import { querystring } from './util'

export type GetPartnerCompanyUsersResponse = {
  count: number
  partnerCompanyInvitedUsers: PartnerCompanyInvitedUser[]
}

type PartnerCompanyInvitedUser = {
  mcdpId: string
  userName: string
  userNameKana: string
  departmentName: string
  position: string
  enabled: boolean
  updateDatetime?: string | null
  userServices?: UserService[]
}

export type UserService = {
  serviceId: number
  enabled: boolean
}

type PartnerCompanyUsersApiParam = {
  prime: boolean
  partnerCompanyId: string
  projectId: string
  companyPositionId: string
  queryParams: {
    limit?: number
    offset?: number
    userNameKana?: string
    departmentName?: string
    serviceIds?: number[]
    invited: boolean
  }
}

type UsePartnerCompanyUsersApi = {
  data: GetPartnerCompanyUsersResponse | undefined
  error: ErrorResponse | null
  loading: boolean
  doFetch: (params: PartnerCompanyUsersApiParam) => void
  clearError: () => void
}

/**
 * 協力会社ユーザーリスト提供API
 * @param param API実行パラメーター
 * @param option
 * @returns
 */
export const usePartnerCompanyUsersApi = (
  param?: PartnerCompanyUsersApiParam,
  option?: GlobalErrorHandlerOption,
): UsePartnerCompanyUsersApi => {
  const { data, error, loading, fetchData, clearError } = useAxiosWrapper(param)
  if (option?.use ?? true) {
    useApiGlobalErrorHandler(error, option)
  }

  const doFetch = (param: PartnerCompanyUsersApiParam) => {
    const url = getUrl(param)
    const { queryParams } = param
    const qs = querystring.stringify(queryParams)
    fetchData({
      method: 'GET',
      url: `${url}?${qs}`,
    })
  }

  return { data, error, loading, doFetch, clearError }
}

/**
 * useAxios()を実行する。(パラメーターによって呼び出し方を変更する)
 * @param param API実行パラメーター
 * @returns
 */
const useAxiosWrapper = (param?: PartnerCompanyUsersApiParam) => {
  if (!param) {
    // パラメーターが無い場合、APIを実行しない
    return useAxios<GetPartnerCompanyUsersResponse>({}, { manual: true })
  }

  const url = getUrl(param)
  const { queryParams } = param
  const qs = querystring.stringify(queryParams)

  return useAxios<GetPartnerCompanyUsersResponse>({
    method: 'GET',
    url: `${url}?${qs}`,
  })
}

const getUrl = (param: PartnerCompanyUsersApiParam) => {
  const { prime, partnerCompanyId, projectId, companyPositionId } = param

  return prime
    ? `/prime/projects/${projectId}/partnerCompanies/${partnerCompanyId}/companyPositions/${companyPositionId}/users`
    : `/partnerCompanies/${partnerCompanyId}/projects/${projectId}/companyPositions/${companyPositionId}/users`
}
