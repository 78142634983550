import { useEffect } from 'react'
import {
  makeStyles,
  Typography,
  Container,
  Card,
  CardContent,
  CardActions,
} from '@material-ui/core'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useTitle, useAuth } from '../../../hooks'
import { TextField, StyledButton } from '../../../components'
import { useDummyLoginApi } from '../../../api'

const useStyles = makeStyles(() => ({
  loginContainer: {
    width: '50vh',
  },
  loginButton: { display: 'flex', justifyContent: 'center' },
}))

type loginCondition = {
  mcdpId: string
}

export const DummyLoginPage: React.FC = () => {
  useTitle('DummyLogin')
  const classes = useStyles()
  const history = useHistory()
  const { setUser } = useAuth()

  const { handleSubmit, register, errors } = useForm<loginCondition>({
    criteriaMode: 'all',
    reValidateMode: 'onSubmit',
  })

  const { data, error, doFetch } = useDummyLoginApi()
  const onSubmit: SubmitHandler<loginCondition> = (data) => {
    doFetch(data)
  }

  useEffect(() => {
    if (!error && data?.mcdpId) {
      setUser(data || null)
      history.push(`/partnerCompanies/${data?.companyId}/projects`)
    }
  }, [data])

  return (
    <Container className={classes.loginContainer}>
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardContent>
            <Typography variant="h4">ダミーログイン</Typography>
            <br />
            <TextField
              variant="outlined"
              size="small"
              margin="dense"
              name="mcdpId"
              label="MCDP-ID"
              inputRef={register}
              formErrors={errors}
              //fetchErrors={error}
            />
          </CardContent>
          <div className={classes.loginButton}>
            <CardActions>
              <StyledButton type="submit" variant="contained">
                ログイン
              </StyledButton>
            </CardActions>
          </div>
        </form>
      </Card>
    </Container>
  )
}
