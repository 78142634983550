import React from 'react'
import { Select, SelectProps } from './Select'
import { FieldErrors } from 'react-hook-form'
import { ErrorResponse } from '../../api'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  select: {
    maxWidth: '15rem',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  errors: {
    display: 'block',
  },
}))

type StyledSelectProps = {
  name: string
  inputRef: React.Ref<unknown>
  formErrors?: FieldErrors
  fetchErrors?: ErrorResponse | null
  fetchName?: string
} & SelectProps

/**
 * 一覧画面で検索条件を入力するためのセレクト
 * @param props
 */
export const StyledSelect: React.FC<StyledSelectProps> = (props) => {
  const { className, errorClassName, formControlClassName, ...rest } = props
  const classes = useStyles()

  return (
    <Select
      className={`${className} ${classes.select}`}
      errorClassName={`${errorClassName} ${classes.errors}`}
      formControlClassName={`${formControlClassName} ${classes.formControl}`}
      {...rest}
    />
  )
}
