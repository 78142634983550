import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { App } from './App'
import reportWebVitals from './reportWebVitals'
import { datadogLogs } from '@datadog/browser-logs'

/**
 * console.errorのログ、キャッチしてない Exception、ネットワークエラーログを自動的に Datadog に Post
 * すべてのセッションでエラーを収集する
 * エラーの分析を改修目的ではなく統計目的で利用する場合などは、sampleRateというパラメーターを調整する
 * 同じサイトのサブドメイン間でセッションを保持しない
 * セッション Cookie、クロスサイトセッション Cookie は使わない
 * https://docs.datadoghq.com/ja/logs/log_collection/javascript/
 */
datadogLogs.init({
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN ?? '',
})

// ビルド時に Faker.js 等のライブラリがプロダクトコードと同一のファイルに含まれることを避ける為、dynamic import とする
const loadMocks = async () => {
  const { worker } = await import('./mocks/index')
  worker.start()
}

const render = () => {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root'),
  )

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals()
}

if (process.env.REACT_APP_API_MOCKING === 'enabled') {
  // MSW を読み込んだ後にレンダリングを行う
  loadMocks().then(render)
} else {
  render()
}
