// ヘルプデスク協力会社企業情報（複数件）APIをfetchするHook

import { ErrorResponse, useAxios } from '.'
import { useApiGlobalErrorHandler } from '../hooks'
import { GlobalErrorHandlerOption } from './types'
import { querystring } from './util'

/**
 * 企業情報
 */
type Company = {
  companyId: string
  companyName: string
  companyTypeName: string
  hasLink: boolean
}

export type HelpdeskCompaniesResponse = {
  companies: Company[]
}

type HelpdeskCompaniesAPIParam = {
  companyId?: string
  companyName?: string
}

type helpdeskCompaniesAPI = {
  data: HelpdeskCompaniesResponse | undefined
  error: ErrorResponse | null
  loading: boolean
  doFetch: (params: HelpdeskCompaniesAPIParam) => void
}

export const useHelpdeskCompaniesApi = (
  param: HelpdeskCompaniesAPIParam,
  option?: GlobalErrorHandlerOption,
): helpdeskCompaniesAPI => {
  const qs = querystring.stringify(param)
  const { data, error, loading, fetchData } =
    useAxios<HelpdeskCompaniesResponse>(
      {
        method: 'GET',
        url: `/helpdesk/partnerCompanies?${qs}`,
      },
      { manual: !option?.initialFetch ?? true },
    )

  if (option?.use ?? true) {
    useApiGlobalErrorHandler(error, option)
  }
  const doFetch = (param: HelpdeskCompaniesAPIParam) => {
    const qs = querystring.stringify(param)
    fetchData({
      method: 'GET',
      url: `/helpdesk/partnerCompanies?${qs}`,
    })
  }

  return { data, error, loading, doFetch }
}
