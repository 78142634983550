import React from 'react'

/**
 * サービス(アクセスポイント)の設定状況 Props
 */
type ServiceEnabledLabelProps = {
  /** 有効フラグ */
  enabled: boolean
}
/**
 * サービス(アクセスポイント)の設定状況を表示するラベル
 * @param props
 * @returns
 */
export const ServiceEnabledLabel: React.FC<ServiceEnabledLabelProps> = (
  props,
) => {
  const { enabled } = props

  return <span>{enabled ? '◯' : 'ー'}</span>
}
