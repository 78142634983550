import logo from './con-nect-header-icon.svg'

type Props = {
  width?: number
  height?: number
}

/**
 * DPPサービスのタイトル画像
 * @returns
 */

export const ProductTitleImage: React.FC<Props> = (props) => {
  const { width = 232, height = 32 } = props

  return (
    <img width={width} height={height} alt="con-nect-service-icon" src={logo} />
  )
}
