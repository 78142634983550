import { Typography, Box, makeStyles } from '@material-ui/core'

type Props = {
  text: string
  color?: 'primary' | 'secondary'
}
const useStyles = makeStyles(() => ({
  contentSubTitle: {
    fontSize: 13,
  },
}))

export const ContentSubTitle: React.FC<Props> = (props) => {
  const classes = useStyles(props)

  return (
    <Box m={1}>
      <Typography className={classes.contentSubTitle} display="inline">
        {props.text}
      </Typography>
    </Box>
  )
}
