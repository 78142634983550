import { createMuiTheme } from '@material-ui/core'
export const Theme = createMuiTheme({
  palette: {
    primary: {
      dark: '#114285',
      main: 'rgb(25,95,190)',
      light: '#477fcb',
    },
    secondary: {
      dark: 'rgb(67, 160, 71)', // todo
      main: 'rgb(67, 160, 71)',
      light: 'rgb(67, 160, 71)', // todo
    },
  },
  overrides: {
    MuiTableCell: {
      root: {
        fontSize: 13,
        border: '1px solid #E0E0E0',
      },
    },
  },
})
