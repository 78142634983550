import { ErrorResponse, useAxios } from '.'
import { User } from '../providers/AuthProvider'

type Response = User

export const useMeApi = (): {
  data: Response | undefined
  error: ErrorResponse | null
  loading: boolean
  doFetch: () => void
} => {
  const { data, error, loading, fetchData } = useAxios<Response>({
    method: 'GET',
    url: `/loginUser`,
  })

  const doFetch = () => {
    fetchData({
      method: 'GET',
      url: `/loginUser`,
    })
  }

  return { data, error, loading, doFetch }
}
