import { useContext } from 'react'
import {
  HistoryQueryContext,
  HistoryQueryContextType,
} from '../providers/HistoryQueryProvider'

/**
 * 履歴のクエリストリングを取得するHook
 */
export const useHistoryQuery = (): HistoryQueryContextType =>
  useContext(HistoryQueryContext)
