import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Grid,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  makeStyles,
  IconButton,
  TableSortLabel,
  TableCellProps,
  Typography,
} from '@material-ui/core'
import { Person } from '@material-ui/icons'
import {
  ContentTitle,
  ContentInfo,
  InlineContainer,
  StyledSelect,
  StyledTextField,
  SearchButton,
  HistoryBackButton,
  StickyTable,
} from '../../../components'
import {
  usePartnerCompanyProjectsApi,
  useInvitedProjectsPrimeCompaniesApi,
  useCompanyPositionsApi,
  ProjectStatus,
  PartnerCompanyProjectsResponse,
  MANAGER_TYPE,
} from '../../../api'
import { useParams } from 'react-router-dom'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useQueryState } from 'react-router-use-location-state'
import { useAuth, useHistoryQuery, useTitle } from '../../../hooks'
import { ListPageTemplate as Template } from '../../template'
import { useThrottledCallback } from 'use-debounce'
import { ErrorMessageContainer } from '../../../components/ErrorMessageContainer'
import { ProjectServiceIcons } from '../../../components/Icon'
import { FooterButtonContainer } from '../../footer'

const NO_DATA_MESSAGE = 'データが見つかりませんでした。'

/**
 * 検索条件項目
 */
type PartnerCompanyProjectsSearchCondition = Partial<{
  projectName: string
  primeCompanyId: string
  companyPositionId: string
  offset: number
}>

type SortDirection = 'asc' | 'desc'

type SortableKey =
  | 'primeCompanyName'
  | 'primeBranchName'
  | 'projectName'
  | 'companyPositionName'
  | 'invitedDate'
  | 'invitedUserCount'

const useStyles = makeStyles(() => ({
  textField: {
    width: '270px',
  },
  description: {
    fontSize: 13,
    marginLeft: 20,
  },
}))

/**
 * プロジェクト一覧画面
 */
export const PartnerCompanyProjectsPage: React.FC = () => {
  const PAGE_TITLE = 'プロジェクト一覧'
  useTitle(PAGE_TITLE)

  const { user } = useAuth()

  // 検索条件をクエリストリングから取得する
  const [projectNameQuery, setProjectNameQuery] = useQueryState(
    'projectName',
    '',
  )
  const [primeCompanyIdQuery, setPrimeCompanyIdQuery] = useQueryState(
    'primeCompanyId',
    '',
  )
  const [companyPositionIdQuery, setCompanyPositionId] = useQueryState(
    'companyPositionId',
    '',
  )
  const [sortByQuery, setSortBy] = useQueryState('sortBy', '')
  const [page, setPage] = useQueryState('page', 0)
  const rowsPerPage = 100

  const classes = useStyles()
  const { partnerCompanyId } = useParams<{ partnerCompanyId: string }>()

  const companyPositions = useCompanyPositionsApi()
  const primeCompanies = useInvitedProjectsPrimeCompaniesApi({
    partnerCompanyId,
  })

  const { data, loading, error, doFetch } = usePartnerCompanyProjectsApi(
    {
      partnerCompanyId,
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      projectName: projectNameQuery,
      primeCompanyId: primeCompanyIdQuery,
      companyPositionId: companyPositionIdQuery,
      sortBy: sortByQuery,
    },
    {
      handleValidateFields: [
        'projectName',
        'primeCompanyId',
        'companyPositionId',
      ],
      message: {
        504: '予期せぬエラーが発生しました。再検索してください。',
      },
    },
  )

  const handleChangePage = (event: unknown, newPage: number) => {
    doFetch({
      partnerCompanyId,
      offset: newPage * rowsPerPage,
      limit: rowsPerPage,
      projectName: projectNameQuery,
      primeCompanyId: primeCompanyIdQuery,
      companyPositionId: companyPositionIdQuery,
      sortBy: sortByQuery,
    })
    setPage(newPage)
  }

  const handleClickSortColumn = (key: SortableKey) => {
    const direction =
      sortKey === key && sortDirection === 'asc' ? 'desc' : 'asc'
    const sortBy = `${key}.${direction}`
    doFetch({
      partnerCompanyId,
      offset: 0,
      limit: rowsPerPage,
      projectName: projectNameQuery,
      primeCompanyId: primeCompanyIdQuery,
      companyPositionId: companyPositionIdQuery,
      sortBy,
    })
    setPage(0)
    setSortBy(sortBy)
  }

  const onSubmit: SubmitHandler<PartnerCompanyProjectsSearchCondition> =
    useThrottledCallback(
      (data) => {
        doFetch({
          partnerCompanyId,
          offset: 0,
          limit: rowsPerPage,
          projectName: data.projectName,
          primeCompanyId: data.primeCompanyId,
          companyPositionId: data.companyPositionId,
          sortBy: sortByQuery,
        })
        setPage(0)
        setProjectNameQuery(data.projectName || '')
        setPrimeCompanyIdQuery(data.primeCompanyId || '')
        setCompanyPositionId(data.companyPositionId || '')
      },
      1000,
      { trailing: false },
    )

  const {
    handleSubmit,
    register,
    errors: formErrors,
  } = useForm<PartnerCompanyProjectsSearchCondition>({
    criteriaMode: 'all',
    reValidateMode: 'onSubmit',
  })

  // 戻り先URL（企業検索画面)
  const historyQuery = useHistoryQuery()
  const backUrl = historyQuery.findByPath(`/helpdesk/companies`)

  /** ログインユーザーの管理者区分がヘルプデスク または MCDP運用担当 */
  const isHelpDeskOrMcdpUser =
    user?.managerType === MANAGER_TYPE.HELP_DESK ||
    user?.managerType === MANAGER_TYPE.MCDP_USER

  // エラーメッセージ
  const [errorMessages, setErrorMessages] = React.useState<string[]>([])
  useEffect(() => {
    const messages = []

    // 検索結果なし
    if (data?.projects.length === 0) {
      messages.push(NO_DATA_MESSAGE)
    }

    // APIエラー
    if (error?.message) {
      messages.push(error.message)
    }

    setErrorMessages(messages)
  }, [data, error])

  const [sortKey, setSortKey] = React.useState<string>()
  const [sortDirection, setSortDirection] = React.useState<SortDirection>()
  useEffect(() => {
    const [currentSortKey, currentSortDirection] = sortByQuery
      ? sortByQuery.split('.')
      : 'invitedDate.desc'.split('.')
    setSortKey(currentSortKey)
    setSortDirection(currentSortDirection as SortDirection)
  }, [sortByQuery])

  return (
    <Template.Container>
      <Template.Body>
        <Template.Header
          loading={companyPositions.loading || primeCompanies.loading}
        >
          <ContentTitle title={PAGE_TITLE} />
          <ContentInfo></ContentInfo>
          <InlineContainer>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container alignItems="flex-start">
                <Grid item xs>
                  <StyledTextField
                    name="projectName"
                    label="作業所名"
                    inputRef={register}
                    formErrors={formErrors}
                    fetchErrors={error}
                    defaultValue={projectNameQuery}
                    className={classes.textField}
                    maxLength={100}
                  />

                  <StyledSelect
                    name="primeCompanyId"
                    label="元請企業"
                    options={primeCompanies.data?.map((x) => ({
                      value: x.primeCompanyId,
                      label: x.primeCompanyName,
                    }))}
                    inputRef={register}
                    fetchErrors={error}
                    defaultValue={primeCompanyIdQuery}
                    data-test="primeCompanyId-input-select"
                  />

                  <StyledSelect
                    name="companyPositionId"
                    label="立場"
                    inputRef={register}
                    options={companyPositions.data?.map((x) => ({
                      value: x.companyPositionId,
                      label: x.companyPositionName,
                    }))}
                    fetchErrors={error}
                    defaultValue={companyPositionIdQuery}
                    data-test="companyPositionId-input-select"
                  />
                </Grid>
                <Grid item>
                  <SearchButton />
                </Grid>
              </Grid>
            </form>
          </InlineContainer>
        </Template.Header>
        {!(companyPositions.loading || primeCompanies.loading) && (
          <Typography className={classes.description}>
            {`プロジェクトへの参加やプロジェクトの利用サービスについては、元請企業にて設定しておりますので、元請企業のご担当者様へお問い合わせください。`}
          </Typography>
        )}

        <Template.ErrorContent loading={loading}>
          <ErrorMessageContainer messages={errorMessages} />
        </Template.ErrorContent>

        <Template.Content loading={loading} hidden={errorMessages.length > 0}>
          <ProjectTable
            projects={data?.projects}
            partnerCompanyId={partnerCompanyId}
            sortKey={sortKey}
            sortDirection={sortDirection}
            handleClickColumnHeader={handleClickSortColumn}
          />
        </Template.Content>
      </Template.Body>

      <Template.Footer loading={loading}>
        {!errorMessages.length && (
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={data?.count ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
          />
        )}

        {isHelpDeskOrMcdpUser && (
          <FooterButtonContainer>
            <HistoryBackButton color="secondary" url={backUrl} />
          </FooterButtonContainer>
        )}
      </Template.Footer>
    </Template.Container>
  )
}

const useStylesTable = makeStyles(() => ({
  tableRow: {
    cursor: 'pointer',
  },
}))

/**
 * 協力会社招待済みプロジェクト一覧テーブル
 * @param props
 * @returns
 */
const ProjectTable: React.FC<{
  projects?: PartnerCompanyProjectsResponse['projects']
  partnerCompanyId: string
  sortKey?: string
  sortDirection?: SortDirection
  handleClickColumnHeader: (key: SortableKey) => void
}> = (props) => {
  const {
    projects,
    partnerCompanyId,
    sortKey,
    sortDirection,
    handleClickColumnHeader,
  } = props
  const history = useHistory()
  const classes = useStylesTable()
  const SortableTableCell: React.FC<
    TableCellProps & {
      name: SortableKey
    }
  > = ({ name, children, ...rest }) => {
    return (
      <TableCell key={name} data-test={`header-${name}`} {...rest}>
        <TableSortLabel
          active={sortKey === name}
          direction={sortKey === name ? sortDirection : undefined}
          onClick={() => handleClickColumnHeader(name)}
        >
          {children}
        </TableSortLabel>
      </TableCell>
    )
  }

  return (
    <StickyTable data-test="partnerCompanyProjects-table">
      <TableHead>
        <TableRow>
          <SortableTableCell
            name="primeCompanyName"
            align="center"
            width="16%"
            style={{ minWidth: 100 }}
          >
            元請会社
          </SortableTableCell>
          <SortableTableCell
            name="primeBranchName"
            align="center"
            width="16%"
            style={{ minWidth: 100 }}
          >
            元請支店
          </SortableTableCell>
          <SortableTableCell
            name="projectName"
            align="center"
            width="32%"
            style={{ minWidth: 100 }}
          >
            作業所
          </SortableTableCell>
          <TableCell
            key="projectServices"
            align="center"
            width="14%"
            style={{ minWidth: 96 }}
          >
            利用サービス
          </TableCell>
          <SortableTableCell
            name="companyPositionName"
            align="center"
            width="10%"
            style={{ minWidth: 92 }}
          >
            役割・立場
          </SortableTableCell>
          <SortableTableCell
            name="invitedDate"
            align="center"
            width="6%"
            style={{ minWidth: 68 }}
          >
            参加日
          </SortableTableCell>
          <SortableTableCell
            name="invitedUserCount"
            align="center"
            width="6%"
            style={{ minWidth: 92 }}
          >
            ユーザー数
          </SortableTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {projects?.map((row) => {
          return (
            <TableRow
              hover
              tabIndex={-1}
              key={row.projectId + row.companyPositionId}
              className={classes.tableRow}
              onClick={() => {
                history.push(
                  `/partnerCompanies/${partnerCompanyId}/projects/${row.projectId}/companyPositions/${row.companyPositionId}/invitedUsers`,
                )
              }}
            >
              <TableCell data-test="primeCompanyName-table-cell">
                {row.primeCompanyName}
              </TableCell>
              <TableCell data-test="primeBranchName-table-cell">
                {row.primeBranchName}
              </TableCell>
              <StyledTableCell data-test="projectName-table-cell">
                <span>{row.projectName}</span>
                {row.projectStatus === ProjectStatus.SUSPEND && (
                  <SuspendLabel />
                )}
              </StyledTableCell>
              <TableCell
                data-test="projectServices-table-cell"
                style={{ fontSize: 0, padding: '8px 16px' }}
              >
                <ProjectServiceIcons services={row.relatedServices} />
              </TableCell>
              <TableCell
                data-test="companyPositionName-table-cell"
                style={{ wordBreak: 'keep-all' }}
              >
                {row.companyPositionName}
              </TableCell>
              <TableCell data-test="invitedDate-table-cell" align="center">
                {row.invitedDate}
              </TableCell>
              <TableCell
                data-test="invitedUserCount-table-cell"
                align="center"
                style={{
                  padding: 0,
                }}
              >
                <PersonWithCountIcon count={row.invitedUserCount} />
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </StickyTable>
  )
}

const PersonWithCountIcon: React.FC<{ count: number }> = ({ count }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      <span style={{ padding: '0 8px', whiteSpace: 'nowrap' }}>
        {count}&nbsp;人
      </span>
      <IconButton aria-label="invited users" style={{ padding: '6px 12px' }}>
        <Person fontSize="large" color="secondary" />
      </IconButton>
    </div>
  )
}

const useTableCellStyles = makeStyles(() => ({
  cell: {
    display: 'flex',
    gap: '12px',
  },
}))

const StyledTableCell: React.FC<TableCellProps> = (props) => {
  const classes = useTableCellStyles()

  return (
    <TableCell {...props}>
      <div className={classes.cell}>{props.children}</div>
    </TableCell>
  )
}

const useSuspendLabelStyles = makeStyles(() => ({
  label: {
    color: 'red',
  },
}))

const SuspendLabel: React.FC = () => {
  const classes = useSuspendLabelStyles()

  return (
    <span className={classes.label} data-test="suspend-label">
      凍結中
    </span>
  )
}
