import { Box, makeStyles } from '@material-ui/core'
import { Redirect, BrowserRouter, Route, Switch } from 'react-router-dom'
import { Header } from './view/header/Header'
import { Footer } from './view/footer/Footer'
import { ErrorHandler } from './components'
import { PrivateRoute } from './PrivateRoute'
import { HistoryQueryProvider } from './providers'

import {
  PartnerCompanyProjectsPage,
  PartnerCompanyUsersPage,
  PartnerCompanyUsersInvitationPage,
  AuthPage,
  AuthCallbackPage,
  DummyLoginPage,
  Logout,
  NotFound,
} from './view/'
import { HelpdeskCompaniesPage } from './view/page/helpdesk/HelpdeskCompaniesPage'

const PAGE_SIDE_MARGIN = 16
const IPAD_PRO_MAX_WIDTH = 1024

const useStyles = makeStyles(() => ({
  root: {
    minWidth: IPAD_PRO_MAX_WIDTH - 2 * PAGE_SIDE_MARGIN,
  },
}))

const RedirectToLogin = () => (
  <Redirect to={process.env.REACT_APP_START_URL ?? `/auth/login`} />
)

const routes = [
  {
    path: '/',
    children: <RedirectToLogin />,
    protect: false,
    exact: true,
  },
  {
    path: '/auth/login',
    children: <AuthPage />,
    description: 'ログインページ',
    protect: false,
    exact: true,
  },
  {
    path: '/auth/callback',
    children: <AuthCallbackPage />,
    description: 'ログインページ（SSOコールバック）',
    protect: false,
    exact: true,
  },
  {
    path: '/auth/login/dummy',
    children: <DummyLoginPage />,
    description: 'ログインページ（開発用ダミー）',
    protect: false,
    exact: true,
    env: ['test'],
  },
  {
    path: '/logout',
    children: <Logout />,
    description: 'ログアウトページ',
    protect: false,
    exact: true,
  },
  {
    path: '/partnerCompanies/:partnerCompanyId/projects',
    children: <PartnerCompanyProjectsPage />,
    description: '協力会社プロジェクト一覧ページ',
    protect: true,
    exact: true,
  },
  {
    path: '/partnerCompanies/:partnerCompanyId/projects/:projectId/companyPositions/:companyPositionId/invitedUsers',
    children: <PartnerCompanyUsersPage prime={false} />,
    description: '協力会社ユーザ一覧ページ',
    protect: true,
    exact: true,
  },
  {
    path: '/prime/projects/:projectId/partnerCompanies/:partnerCompanyId/companyPositions/:companyPositionId/invitedUsers',
    children: <PartnerCompanyUsersPage prime={true} />,
    description: '協力会社ユーザ一覧ページ（ログインユーザが元請の場合）',
    protect: true,
    exact: true,
  },
  {
    path: '/partnerCompanies/:partnerCompanyId/projects/:projectId/companyPositions/:companyPositionId/users/invitation',
    children: <PartnerCompanyUsersInvitationPage prime={false} />,
    description: '協力会社ユーザ追加ページ',
    protect: true,
    exact: true,
  },
  {
    path: '/prime/projects/:projectId/partnerCompanies/:partnerCompanyId/companyPositions/:companyPositionId/users/invitation',
    children: <PartnerCompanyUsersInvitationPage prime={true} />,
    description: '協力会社ユーザ追加ページ（ログインユーザが元請の場合）',
    protect: true,
    exact: true,
  },
  {
    path: '/helpdesk/companies',
    children: <HelpdeskCompaniesPage />,
    description: 'ヘルプデスク企業検索',
    protect: true,
    exact: true,
  },
].filter(
  // Pathに環境区分が設定されている場合は対象の環境の場合にのみ有効にする。
  (x) =>
    !x.env ||
    !process.env.REACT_APP_ENV ||
    x.env.some((env) => env === process.env.REACT_APP_ENV),
)

export const Routes = (): JSX.Element => {
  const classes = useStyles()

  return (
    <BrowserRouter>
      <HistoryQueryProvider>
        <Box className={classes.root}>
          <Header />
          <main>
            <ErrorHandler>
              <Switch>
                {routes.map(({ protect, children, ...rest }) => {
                  const R = protect ? PrivateRoute : Route

                  return (
                    <R {...rest} key={rest.path}>
                      {children}
                    </R>
                  )
                })}

                <Route component={NotFound} />
              </Switch>
            </ErrorHandler>
          </main>
          <Footer />
        </Box>
      </HistoryQueryProvider>
    </BrowserRouter>
  )
}
