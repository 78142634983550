import { Box, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { PartnerCompanyProjectResponse } from '../../../api'
import { ProjectServiceIcons } from './ProjectServiceIcons'

const useStyles = makeStyles(() => ({
  contentSubTitle: {
    fontSize: 13,
  },
  projectService: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 8px 8px 8px',
  },
}))

/**
 * プロジェクト関連サービスアイコン
 * @param props
 * @returns
 */
export const ProjectServiceIconsWithCaption: React.FC<{
  services?: PartnerCompanyProjectResponse['relatedServices']
  caption?: string
}> = (props) => {
  const classes = useStyles(props)
  const { services, caption } = props

  if (!services?.length) {
    return null
  }

  return (
    <Box m={1} className={classes.projectService}>
      <Typography className={classes.contentSubTitle}>
        {caption ?? ''}
      </Typography>
      <ProjectServiceIcons services={services} />
    </Box>
  )
}
