import React from 'react'
import {
  InputLabel,
  FormControl,
  Select as MuiSelect,
  FormHelperText,
  SelectProps as MuiSelectProps,
} from '@material-ui/core'
import { ErrorResponse } from '../../api'
import { FieldErrors, get } from 'react-hook-form'
import { SelectInputProps } from '@material-ui/core/Select/SelectInput'

export type SelectProps = {
  id?: string
  options?: React.DetailedHTMLProps<
    React.OptionHTMLAttributes<HTMLOptionElement>,
    HTMLOptionElement
  >[]
  label: string
  /** APIレスポンスで返ってきたエラー */
  fetchErrors?: ErrorResponse | null
  /**
   * APIレスポンスで返ってくる場合の項目名
   * 未指定の場合、nameが使われる
   */
  fetchName?: string
  formErrors?: FieldErrors
  name: string
  formControlClassName?: string
  errorClassName?: string
  onChange?: SelectInputProps['onChange']
} & MuiSelectProps

export const Select: React.FC<SelectProps> = (props) => {
  // value と defaultValue を同時に Select に設定できないのでここで defaultValue を取り出しておく。
  const {
    label,
    options,
    fetchErrors,
    fetchName,
    formErrors,
    name,
    defaultValue,
    onChange,
    className,
    errorClassName,
    formControlClassName,
    ...rest
  } = props

  const [value, setValue] = React.useState(defaultValue)
  const handleChange: SelectInputProps['onChange'] = (event) => {
    setValue(event.target.value)
    if (onChange) onChange(event, null)
  }

  const id = props.id || Math.random().toString(32).substring(2)

  const formMessages: string[] = Object.values(
    get(formErrors, name)?.types || {},
  )
  const fetchMessages =
    fetchErrors?.errors
      ?.filter((e) => e.field === (fetchName || name))
      ?.map((e) => e.message) || []

  const messages = [...formMessages, ...fetchMessages].map((m, i) => {
    return (
      <FormHelperText key={m + i} className={errorClassName}>
        {m}
      </FormHelperText>
    )
  })

  return (
    <FormControl
      variant="outlined"
      size="small"
      margin="dense"
      error={messages.length > 0}
      className={formControlClassName}
    >
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <MuiSelect
        name={name}
        native
        label={label}
        className={className}
        value={value}
        onChange={handleChange}
        {...rest}
      >
        <option aria-label="None" value="" />
        {options?.map((option) => {
          return (
            <option key={option.label} value={option.value}>
              {option.label}
            </option>
          )
        })}
      </MuiSelect>
      {messages}
    </FormControl>
  )
}
