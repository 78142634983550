import { Typography, Box, makeStyles } from '@material-ui/core'

type Props = {
  title: string
  color?: 'primary' | 'secondary'
}
const useStyles = makeStyles((theme) => ({
  contentTitle: {
    fontSize: 16,
    marginTop: '8px',
    borderLeft: '5px solid',
    borderLeftColor: (props: Props) =>
      props.color === 'primary'
        ? theme.palette.primary.main
        : theme.palette.secondary.main,
    paddingLeft: '.5rem',
    lineHeight: 1.5,
    whiteSpace: 'nowrap',
  },
  contentTitleRoot: {
    display: 'inline-flex',
  },
}))

export const ContentTitle: React.FC<Props> = (props) => {
  const classes = useStyles(props)

  return (
    <Box m={1} className={classes.contentTitleRoot}>
      <h1 className={classes.contentTitle}>
        <Typography display="inline">{props.title}</Typography>
      </h1>
    </Box>
  )
}
