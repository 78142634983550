import { makeStyles } from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'

const useStyles = makeStyles((theme) => ({
  container: {
    color: theme.palette.error.main,
  },
  icon: {
    marginTop: '0px',
    display: 'inline-flex',
    verticalAlign: 'top',
  },
}))

export const ErrorMessageContainer: React.FC<{
  messages?: string[]
}> = (props) => {
  const classes = useStyles()
  const { messages } = props

  return (
    <div>
      {(messages || []).map((message) => {
        return (
          <div className={classes.container}>
            <ErrorIcon className={classes.icon} />
            <span data-test="error-message">{message}</span>
          </div>
        )
      })}
    </div>
  )
}
