import { Grid, makeStyles, Typography } from '@material-ui/core'
import { ReactNode } from 'react'

const useStyles = makeStyles({
  footer: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    width: '100%',
    backgroundColor: '#757575',
  },
  footerText: {
    color: 'white',
    lineHeight: '36px',
  },
})

const BaseFooter: React.FC<{ children: ReactNode }> = ({ children }) => {
  const classes = useStyles()

  return (
    <footer className={classes.footer}>
      <Grid container item alignItems="center" justify="center">
        <Typography variant="body2" className={classes.footerText}>
          {children}
        </Typography>
      </Grid>
    </footer>
  )
}

export const Footer: React.FC = () => {
  return (
    <BaseFooter>
      Copyright (C) MC Data Plus, Inc. All Rights Reserved.
    </BaseFooter>
  )
}
