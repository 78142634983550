import React from 'react'
import { TextField } from './TextField'
import { FieldErrors } from 'react-hook-form'
import { ErrorResponse } from '../../api'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  errors: {
    display: 'block',
  },
  textField: {
    marginLeft: '4px',
    marginRight: '4px',
  },
})

type StyledTextFieldProps = {
  name: string
  label: string
  inputRef: React.Ref<unknown>
  defaultValue?: unknown
  formErrors: FieldErrors
  fetchErrors?: ErrorResponse | null
  fetchName?: string
  className?: string
  errorClassName?: string
  maxLength?: number
  children?: React.ReactNode;
}

/**
 * 一覧画面で検索条件を入力するためのテキストフィールド
 * @param props
 */
export const StyledTextField: React.FC<StyledTextFieldProps> = (props) => {
  const { children, className, errorClassName, ...rest } = props
  const classes = useStyles()

  return (
    <TextField
      variant="outlined"
      size="small"
      margin="dense"
      className={`${className} ${classes.textField}`}
      errorClassName={`${errorClassName} ${classes.errors}`}
      {...rest}
    >
      {children}
    </TextField>
  )
}
