import React from 'react'

export const PAGE_FOOTER_BUTTON_HEIGHT = '54px'

type Props = {
  children: React.ReactNode;
};

export const FooterButtonContainer: React.FC<Props> = ({ children }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexBasis: '100%',
        alignItems: 'flex-end',
        justifyContent: 'center',
        height: PAGE_FOOTER_BUTTON_HEIGHT,
      }}
    >
      {children}
    </div>
  )
}
