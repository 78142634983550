import React from 'react'
import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  InputProps,
} from '@material-ui/core'
import { FieldErrors, get } from 'react-hook-form'
import { ErrorResponse } from '../../api'

type TextFieldProps = MuiTextFieldProps & {
  /** 入力項目の物理名 */
  name: string
  /** 下部に表示するヘルパーテキスト */
  defaultHelperText?: React.ReactNode
  /** React Hook Formで出されたエラー */
  formErrors: FieldErrors
  /** APIレスポンスで返ってきたエラー */
  fetchErrors?: ErrorResponse | null
  /**
   * APIレスポンスで返ってくる場合の項目名
   * 未指定の場合、nameが使われる
   */
  fetchName?: string
  maxLength?: number
  errorClassName?: string
}

/**
 * クライアント・サーバーでのエラーを表示できるようにしたTextField
 * @param props
 */
export const TextField: React.FC<TextFieldProps> = (props) => {
  const {
    name,
    formErrors,
    fetchErrors,
    fetchName,
    defaultHelperText,
    children,
    className,
    errorClassName,
    maxLength,
    ...rest
  } = props

  const formMessages: string[] = Object.values(
    get(formErrors, name)?.types || {},
  )
  const fetchMessages =
    fetchErrors?.errors
      ?.filter((e) => e.field === (fetchName || name))
      ?.map((e) => e.message) || []
  const messages = formMessages.concat(fetchMessages).map((m, i) => {
    return (
      <span key={i} className={errorClassName}>
        {m}
      </span>
    )
  })

  const inputProps: InputProps['inputProps'] = {}
  if (maxLength) {
    inputProps.maxLength = maxLength
  }

  return (
    <MuiTextField
      name={name}
      className={className}
      error={messages.length > 0}
      helperText={messages.length > 0 ? messages : defaultHelperText}
      data-test={`${name}-input-text-field`}
      inputProps={inputProps}
      {...rest}
    >
      {children}
    </MuiTextField>
  )
}
