import React from 'react'
import {
  makeStyles,
  Paper,
  Table,
  TableContainer,
  TableProps,
} from '@material-ui/core'

const useStylesTable = makeStyles(() => ({
  tableContainer: {
    // container の overflow-x に auto が設定されているとヘッダ固定化が解除されてしまうので再設定
    overflowX: 'inherit',
  },
}))

/**
 * スクロール時にヘッダを固定化する Table コンポーネント
 * @param props
 */
export const StickyTable: React.FC<TableProps> = (props) => {
  const { children, ...rest } = props

  const classes = useStylesTable()

  return (
    <Paper elevation={0} variant="outlined">
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader {...rest}>
          {children}
        </Table>
      </TableContainer>
    </Paper>
  )
}
