import React from 'react'
import { Route, useHistory } from 'react-router-dom'
import { useAuth, useCurrentUrl } from './hooks'
import { ContentBackdrop } from './components'

type Props = {
  children: React.ReactNode;
};

export const PrivateRoute: React.FC<Props> = ({
  children,
  ...rest
}): JSX.Element => {
  const { user, loading } = useAuth()
  const history = useHistory()
  const { setCurrentUrl } = useCurrentUrl()

  // /auth/loginがカレントURLだった場合に認証処理がループするのを回避する。
  if (!history.location.pathname.match(/\/auth/g)) {
    setCurrentUrl(history.location.pathname)
  }

  if (loading) {
    return <ContentBackdrop />
  } else {
    if (!user) {
      // ユーザー情報がない場合は再認証させる。
      // URL直打ちの場合はsessionStorageにURLを保存して再認証後にリダイレクトする。
      history.replace('/auth/login')
    }
  }

  return <Route {...rest} render={() => children} />
}
