import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  container: {
    display: 'inline-flex',
    marginLeft: 'auto',
  },
}))

type Props = {
  children: React.ReactNode;
};

export const InlineContainer: React.FC<Props> = (props) => {
  const classes = useStyles()

  return <div className={classes.container}>{props.children}</div>
}
