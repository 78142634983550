import React from 'react'
import {
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core'
import { StyledButton } from './'

type DialogConfirmProps = {
  onCancelClick?: () => void
  onOKClick?: () => void
} & DialogProps

export const DialogConfirm: React.FC<DialogConfirmProps> = (param) => {
  const { onCancelClick, onOKClick, ...props } = param

  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...props}
      onClose={onCancelClick}
      open={props.open}
    >
      <DialogTitle id="alert-dialog-title">{props.title || '確認'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" color="textPrimary">
          {props.children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <StyledButton onClick={onCancelClick} data-test="cancel-button">
          キャンセル
        </StyledButton>
        <StyledButton
          variant="contained"
          color="secondary"
          onClick={onOKClick}
          data-test="enter-button"
        >
          確定
        </StyledButton>
      </DialogActions>
    </Dialog>
  )
}
