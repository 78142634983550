import { StyledButton, StyledButtonProps } from './StyledButton'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  button: {
    margin: '8px 4px',
    height: '38px',
  },
}))
type SearchButtonProps = StyledButtonProps

export const SearchButton: React.FC<SearchButtonProps> = () => {
  const classes = useStyles()

  return (
    <StyledButton
      type="submit"
      variant="contained"
      size="small"
      data-test="search-submit"
      className={classes.button}
    >
      検索
    </StyledButton>
  )
}
