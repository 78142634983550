import { makeStyles, Typography } from '@material-ui/core'
import { useTitle } from '../../hooks'

const useStyles = makeStyles(() => ({
  page: {
    textAlign: 'center',
  },
}))

export const Forbidden: React.FC = () => {
  useTitle('Forbidden')
  const classes = useStyles()

  return (
    <div className={classes.page}>
      <Typography variant="h1">403</Typography>
      <br />
      <Typography variant="h4">Forbidden</Typography>
      <br />
      <Typography variant="h6">
        指定されたページの閲覧は制限されています。
      </Typography>
    </div>
  )
}
