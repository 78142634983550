import { ErrorResponse, useAxios } from '.'

export const useLogoutApi = (): {
  error: ErrorResponse | null
  loading: boolean
  doPost: () => void
} => {
  const { error, loading, fetchData } = useAxios(
    {
      method: 'POST',
      url: `/logout`,
    },
    { manual: true },
  )

  const doPost = () => {
    fetchData({
      method: 'POST',
      url: `/logout`,
    })
  }

  return { error, loading, doPost }
}
