import React from 'react'
import { StyledButton, DialogConfirm } from '..'

/**
 * 確認ダイアログつきボタン
 */
type ConfirmDialogButtonProps = {
  handler: () => void
  disabled: boolean
  buttonText: string
  dialogConfirmText: string
  color: 'primary' | 'secondary'
  name: string
}

/**
 * 確認ダイアログつきボタン
 * @returns
 */
export const ConfirmDialogButton: React.FC<ConfirmDialogButtonProps> = ({
  disabled,
  handler,
  buttonText,
  dialogConfirmText,
  color,
  name,
}) => {
  // 確認ダイアログ表示
  const [dialogConfirmOpen, setDialogConfirmOpen] = React.useState(false)

  return (
    <>
      <StyledButton
        variant="contained"
        color={color}
        onClick={() => setDialogConfirmOpen(true)}
        disabled={disabled}
        data-test={`${name}-button`}
      >
        {buttonText}
      </StyledButton>
      <DialogConfirm
        open={dialogConfirmOpen}
        onCancelClick={() => {
          setDialogConfirmOpen(false)
        }}
        onOKClick={() => {
          handler()
          setDialogConfirmOpen(false)
        }}
      >
        {dialogConfirmText}
      </DialogConfirm>
    </>
  )
}
