import React, { ReactNode } from 'react'
import { makeStyles } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

/**
 * Con-nect タイトルヘッダー（白）と、AppBar（緑色）の高さの合計
 */
const HEADER_HEIGHT = '118px'
/**
 * Copyrightフッター（グレー）の高さ
 */
const FOOTER_HEIGHT = '36px'

/**
 * ページコンテンツの高さ
 */
const PAGE_CONTENT_HEIGHT = '60vh'

/**
 * ページコンテンツ箇所の両側に設けるマージンのサイズ
 */
const BOTH_SIDES_WIDTH = '16px'

// IPad の場合 100vh はアドレスバーの高さも含めたサイズとなる。
// 一般的にはこれに対処するために、 -webkit-fill-available を利用するか、JSで判断する方法が採用される。
// -webkit-fill-available がうまく実現できないため、JSで判断している
// https://zenn.dev/tak_dcxi/articles/2ac77656aa94c2cd40bf
const userAgent = window.navigator.userAgent.toLowerCase()
const isIPhone = userAgent.indexOf('iphone') !== -1
const isIpad = userAgent.indexOf('ipad') !== -1
const isIOS = isIPhone || isIpad
const IOS_ADDRESS_BAR_LENGTH = '75px'
const addressBarLength = isIOS ? IOS_ADDRESS_BAR_LENGTH : '0px'

/**
 * ページコンポーネントのスタイル
 */
const usePageStyles = makeStyles(() => ({
  pageContainer: {
    width: '100%',
    height: `calc(100vh - ${HEADER_HEIGHT} - ${FOOTER_HEIGHT} - ${addressBarLength})`,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '8px',
  },
  pageBody: {
    overflowY: 'auto',
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  pageHeader: {
    display: 'inline-flex',
    flexFlow: 'row wrap',
    marginBottom: '8px',
    marginLeft: BOTH_SIDES_WIDTH,
    marginRight: BOTH_SIDES_WIDTH,
  },
  pageErrorContent: {
    marginBottom: '8px',
    marginLeft: BOTH_SIDES_WIDTH,
    marginRight: BOTH_SIDES_WIDTH,
  },
  pageContent: {
    marginLeft: BOTH_SIDES_WIDTH,
    marginRight: BOTH_SIDES_WIDTH,
  },
  pageFooter: {
    marginTop: 'auto',
    marginLeft: BOTH_SIDES_WIDTH,
    marginRight: BOTH_SIDES_WIDTH,
  },
}))

type Props = {
  children: React.ReactNode;
};

/**
 * ページテンプレート コンテナー
 * @param props
 * @returns
 */
export const PageContainer: React.FC<Props> = (props) => {
  const { pageContainer } = usePageStyles()

  return <div className={pageContainer}>{props.children}</div>
}

/**
 * ページテンプレート ボディ（ヘッダー、エラーコンテンツ、コンテンツを含める）
 */
export const PageBody: React.FC<Props> = (props) => {
  const { pageBody } = usePageStyles()

  return <div className={pageBody}>{props.children}</div>
}

type PageHeaderProps = {
  loading?: boolean
  children: ReactNode
}

/**
 * ページテンプレート ヘッダー
 * タイトル、検索条件、ページ補足情報などを表示する
 * @param props
 * @returns
 */
export const PageHeader: React.FC<PageHeaderProps> = ({
  children,
  loading = false,
}) => {
  const { pageHeader } = usePageStyles()
  const skelton = <Skeleton variant="rect" height={HEADER_HEIGHT} />

  return (
    <div className={pageHeader} data-test="page-header">
      {loading ? skelton : children}
    </div>
  )
}

type PageErrorContentProps = {
  loading?: boolean
  children: ReactNode
}

/**
 * ページテンプレート エラーコンテンツ
 * ページエラー、検索エラーなどを表示する
 * @param props
 * @returns
 */
export const PageErrorContent: React.FC<PageErrorContentProps> = ({
  children,
  loading = false,
}) => {
  const { pageErrorContent } = usePageStyles()

  return (
    <div className={pageErrorContent} data-test="page-errorcontent">
      {loading ? null : children}
    </div>
  )
}

type PageContentProps = {
  loading?: boolean
  children: ReactNode
  hidden?: boolean
  height?: number | string
}
/**
 * ページテンプレート コンテンツ
 * @param props
 * @returns
 */

export const PageContent: React.FC<PageContentProps> = ({
  children,
  loading = false,
  hidden = false,
  height = PAGE_CONTENT_HEIGHT,
}) => {
  const { pageContent } = usePageStyles()
  const skelton = <Skeleton variant="rect" height={height} />

  return hidden ? null : (
    <div className={pageContent} data-test="page-content">
      {loading ? skelton : children}
    </div>
  )
}

type PageFooterProps = {
  loading?: boolean
  children: ReactNode
  hidden?: boolean
}

/**
 * ページテンプレート フッター
 * 登録ボタン、戻るボタン、ページングコンポーネントなどを表示する。
 * @param props
 * @returns
 */
export const PageFooter: React.FC<PageFooterProps> = ({
  children,
  loading = false,
  hidden = false,
}) => {
  const { pageFooter } = usePageStyles()

  return hidden ? null : (
    <div className={pageFooter} data-test="page-footer">
      {loading ? null : children}
    </div>
  )
}

export const ListPageTemplate = {
  Container: PageContainer,
  Body: PageBody,
  Header: PageHeader,
  Content: PageContent,
  ErrorContent: PageErrorContent,
  Footer: PageFooter,
}
