import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  AuthCallBackAPIParams,
  MANAGER_TYPE,
  useAuthCallbackApi,
} from '../../../api'
import { useAuth, useCurrentUrl } from '../../../hooks'
import { ContentBackdrop } from '../../../components'
import qs from 'qs';

const useLoginCallback = () => {
  const history = useHistory()
  const { setUser } = useAuth()
  const { getCurrentUrl, removeCurrentUrl } = useCurrentUrl()

  const params = qs.parse(window.location.search.slice(1)) // ? を除去するために slice(1) している
  const { data, error, loading } = useAuthCallbackApi(
    params as AuthCallBackAPIParams,
  )

  const currentUrl = getCurrentUrl()

  useEffect(() => {
    if (error) {
      const path = history.location.pathname + history.location.search
      if (error?.status === 401) {
        history.replace(path, {
          errorStatusCode: 401,
          showRedirectToLogin: true,
        })
      } else {
        history.replace(path, {
          errorStatusCode: 500,
        })
      }
    }

    if (data) {
      setUser(data)
      removeCurrentUrl()
      const isAdmin =
        data.managerType === MANAGER_TYPE.HELP_DESK ||
        data.managerType === MANAGER_TYPE.MCDP_USER

      const partnerCompaniesPage = `/partnerCompanies/${data.companyId}/projects`
      const helpDeskCompanies = `/helpdesk/companies`
      const url = isAdmin ? helpDeskCompanies : partnerCompaniesPage

      history.replace({
        pathname: currentUrl ?? url,
      })
    }
  }, [data, error])

  return { loading }
}

export const AuthCallbackPage: React.FC = () => {
  const { loading } = useLoginCallback()

  return loading ? <ContentBackdrop /> : <></>
}
