import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuthApi } from '../../../api'
import { ContentBackdrop } from '../../../components'

const useLogin = () => {
  const history = useHistory()
  const { data, error, loading } = useAuthApi()

  useEffect(() => {
    if (error) {
      history.replace(history.location.pathname + history.location.search, {
        errorStatusCode: 500,
      })
    }
    if (data) {
      window.location.assign(data.authorizationUrl)
    }
  }, [data, error])

  return { loading }
}

export const AuthPage: React.FC = () => {
  const { loading } = useLogin()

  return loading ? <ContentBackdrop /> : <></>
}
