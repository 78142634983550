import { makeStyles, Typography } from '@material-ui/core'
import { useTitle } from '../../hooks'

const useStyles = makeStyles(() => ({
  page: {
    textAlign: 'center',
  },
}))

export const NotFound: React.FC = () => {
  useTitle('Not Found')
  const classes = useStyles()

  return (
    <div className={classes.page}>
      <Typography variant="h1">404</Typography>
      <br />
      <Typography variant="h4">Not Found</Typography>
      <br />
      <Typography variant="h6">
        指定されたページは見つかりませんでした。
      </Typography>
    </div>
  )
}
