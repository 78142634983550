import * as qs from 'query-string'

type Obj = {
  // TODO: ちゃんと型をつけてテストする
  // eslint-disable-next-line
  [key: string]: any
}

/**
 * undefined または null または 空文字 をもつフィールドを削除する
 * @param obj
 */
export const clean = (obj: Obj): Obj => {
  const copy = { ...obj }
  const isNullOrBlankOrUndefined = (o: string | null | undefined | number) =>
    o === null || o === undefined || o === ''
  Object.keys(copy).forEach(
    (key) => isNullOrBlankOrUndefined(copy[key]) && delete copy[key],
  )

  return copy
}

export const querystring = {
  stringify(params: Obj): string {
    return qs.stringify(clean(params), { arrayFormat: 'comma' })
  },
}

/**
 * カンマ区切り文字列を数値配列に変換する
 * @param string
 */
export const parseNumArray = (str: string): number[] => {
  return str.length
    ? str
        .split(',')
        .filter((v) => !isNaN(Number(v)))
        .map((v) => {
          return Number(v)
        })
    : []
}
