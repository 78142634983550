import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { SubmitHandler, useForm } from 'react-hook-form'
import {
  Grid,
  TablePagination,
  makeStyles,
  Typography,
} from '@material-ui/core'
import {
  ContentTitle,
  HistoryBackButton,
  InlineContainer,
  SearchButton,
  MultiSelectCheckbox,
  SelectCheckboxProps,
  StyledTextField,
  ErrorMessageContainer,
  ContentSubTitle,
  ProjectServiceIconsWithCaption,
  ConfirmDialogButton,
} from '../../../components'
import {
  PrimeCompanyServicesResponse,
  usePartnerCompanyProjectApi,
  usePartnerCompanyUsersApi,
  usePartnerCompanyUsersPostApi,
  usePrimeCompanyServicesApi,
  UserService,
} from '../../../api'
import { useTitle, useHistoryQuery } from '../../../hooks'
import { useQueryState } from 'react-router-use-location-state'
import { useSnackbar } from 'notistack'
import { ListPageTemplate as Template } from '../../template'
import { parseNumArray } from '../../../api/util'
import { useThrottledCallback } from 'use-debounce'
import { FooterButtonContainer } from '../../footer'
import { PartnerCompanyUserTable } from './PartnerCompanyUserTable'

const NO_DATA_MESSAGE = 'データが見つかりませんでした。'
const PAGE_CONTENT_HEIGHT = '50vh'

/**
 * 検索条件項目
 */
type PartnerCompanyUsersInvitationSearchCondition = Partial<{
  userNameKana: string
  departmentName: string
  primeCompanyServices: number[]
}>

/**
 * 一覧データ項目
 */
type UserTableRowData = {
  primeCompanyServices: boolean[]
  mcdpId: string
  userName: string
  userNameKana: string
  departmentName: string
  position: string
  enabled: boolean
  updateDatetime?: string | null
  userServices?: UserService[]
  key: string
  selected: boolean // 表の左部分のチェックボックスの有無を判定するフラグ
}

/**
 * スタイル(CSS)定義
 */
const useStyles = makeStyles(() => ({
  multiSelectCheckbox: {
    width: '547px', // レスポンシブ的にしたいが固定幅でないと検索条件が崩れるのでこのようにする。
  },
  textField: {
    width: '270px',
  },
  description: {
    fontSize: 13,
    marginLeft: 24,
    whiteSpace: 'pre-line',
  },
}))

/**
 * パスパラメーター項目
 */
type PartnerCompanyUsersInvitationParams = {
  partnerCompanyId: string
  projectId: string
  companyPositionId: string
}

type PartnerCompanyUsersInvitationProps = {
  prime: boolean
}

/**
 * 協力会社ユーザー追加画面
 */
export const PartnerCompanyUsersInvitationPage: React.FC<PartnerCompanyUsersInvitationProps> =
  (props) => {
    const PAGE_TITLE = '協力会社ユーザー追加'
    useTitle(PAGE_TITLE)

    // 検索条件をクエリストリングから取得する
    const [userNameKanaQuery, setUserNameKanaQuery] = useQueryState(
      'userNameKana',
      '',
    )
    const [departmentNameQuery, setDepartmentNameQuery] = useQueryState(
      'departmentName',
      '',
    )
    const [serviceIdsQuery, setServiceIdsQuery] = useQueryState(
      'serviceIds',
      '',
    )
    const [page, setPage] = useQueryState('page', 0)
    const rowsPerPage = 100

    const classes = useStyles()
    const params = useParams<PartnerCompanyUsersInvitationParams>()
    const historyQuery = useHistoryQuery()

    // 協力会社招待プロジェクト提供（1件） ※ステータスが「稼働中」のみ取得
    const partnerCompanyProject = usePartnerCompanyProjectApi({
      prime: props.prime,
      partnerCompanyId: params.partnerCompanyId,
      projectId: params.projectId,
      companyPositionId: params.companyPositionId,
      running: true,
    })

    const primeCompanyServices = usePrimeCompanyServicesApi({
      prime: props.prime,
      partnerCompanyId: params.partnerCompanyId,
      projectId: params.projectId,
    })

    // サービス利用状況の選択状態
    const [selectedServiceIds, setSelectedServiceIds] = React.useState<
      string[]
    >([])
    useEffect(() => {
      setSelectedServiceIds(serviceIdsQuery.split(','))
    }, [serviceIdsQuery])

    const checkboxes =
      primeCompanyServices.data?.map<SelectCheckboxProps>((x) => {
        return { value: x.serviceId.toString(), label: x.serviceName }
      }) ?? []

    const { doFetch, ...partnerCompanyUsers } = usePartnerCompanyUsersApi(
      {
        prime: props.prime,
        partnerCompanyId: params.partnerCompanyId,
        projectId: params.projectId,
        companyPositionId: params.companyPositionId,
        queryParams: {
          offset: page * rowsPerPage,
          limit: rowsPerPage,
          userNameKana: userNameKanaQuery,
          departmentName: departmentNameQuery,
          serviceIds: parseNumArray(serviceIdsQuery),
          invited: false,
        },
      },
      {
        handleValidateFields: ['userNameKana', 'departmentName', 'serviceIds'],
        message: {
          504: '予期せぬエラーが発生しました。再検索してください。',
        },
      },
    )

    // 一覧表示用データ
    const [users, setUsers] = React.useState<UserTableRowData[]>([])
    useEffect(() => {
      const getUserServicesEnabled = (
        primeCompanyServices?: PrimeCompanyServicesResponse,
        userServices?: UserService[],
      ) => {
        return (
          primeCompanyServices?.map(
            (y) =>
              userServices?.find((x) => x.serviceId === y.serviceId)?.enabled ??
              false,
          ) ?? []
        )
      }

      const users =
        partnerCompanyUsers.data?.partnerCompanyInvitedUsers?.map((x) => ({
          key: x.mcdpId,
          selected: false,
          primeCompanyServices: getUserServicesEnabled(
            primeCompanyServices.data,
            x.userServices,
          ),
          ...x,
        })) ?? []
      setUsers(users)
    }, [partnerCompanyUsers.data, primeCompanyServices.data])

    const { doPost, ...partnerCompanyUsersPost } =
      usePartnerCompanyUsersPostApi({
        notFoundRedirect: false,
      })

    // ユーザー追加処理後のレスポンスデータを監視
    const { enqueueSnackbar } = useSnackbar()
    useEffect(() => {
      if (!partnerCompanyUsersPost.data) {
        // エラー時(データが取得できていない場合)は処理終了
        return
      }

      enqueueSnackbar('ユーザーをプロジェクトに追加しました（元請会社のシステムに反映されるまで暫くお待ちください）。', {
        variant: 'success',
        anchorOrigin: { horizontal: 'left', vertical: 'top' },
      })

      // 表示ページをリフレッシュ
      doFetch({
        prime: props.prime,
        partnerCompanyId: params.partnerCompanyId,
        projectId: params.projectId,
        companyPositionId: params.companyPositionId,
        queryParams: {
          offset: page * rowsPerPage,
          limit: rowsPerPage,
          userNameKana: userNameKanaQuery,
          departmentName: departmentNameQuery,
          serviceIds: parseNumArray(serviceIdsQuery),
          invited: false,
        },
      })
    }, [partnerCompanyUsersPost.data])

    const pageContentLoading =
      partnerCompanyUsers.loading ||
      primeCompanyServices.loading ||
      partnerCompanyUsersPost.loading

    /**
     * ページヘッダーのエラーメッセージをクリアする。
     */
    const clearPageHeaderErrorMessage = () => {
      partnerCompanyUsers.clearError()
      partnerCompanyUsersPost.clearError()
    }

    /**
     * 検索ボタンクリック(submit)ハンドラ
     * @param data 検索条件
     */
    const onSubmit: SubmitHandler<PartnerCompanyUsersInvitationSearchCondition> =
      useThrottledCallback(
        (data) => {
          clearPageHeaderErrorMessage()
          doFetch({
            prime: props.prime,
            partnerCompanyId: params.partnerCompanyId,
            projectId: params.projectId,
            companyPositionId: params.companyPositionId,
            queryParams: {
              offset: 0,
              limit: rowsPerPage,
              userNameKana: data.userNameKana,
              departmentName: data.departmentName,
              serviceIds: data.primeCompanyServices,
              invited: false,
            },
          })
          setPage(0)
          setUserNameKanaQuery(data.userNameKana || '')
          setDepartmentNameQuery(data.departmentName || '')
          setServiceIdsQuery(data.primeCompanyServices?.join(',') || '')
        },
        1000,
        { trailing: false },
      )

    const { handleSubmit, register, errors } =
      useForm<PartnerCompanyUsersInvitationSearchCondition>({
        criteriaMode: 'all',
        reValidateMode: 'onSubmit',
      })

    /**
     * ページ変更ハンドラ
     * @param event イベント
     * @param newPage ページ番号
     */
    const handleChangePage = (
      _event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
      newPage: number,
    ) => {
      clearPageHeaderErrorMessage()
      doFetch({
        prime: props.prime,
        partnerCompanyId: params.partnerCompanyId,
        projectId: params.projectId,
        companyPositionId: params.companyPositionId,
        queryParams: {
          offset: newPage * rowsPerPage,
          limit: rowsPerPage,
          userNameKana: userNameKanaQuery,
          departmentName: departmentNameQuery,
          serviceIds: parseNumArray(serviceIdsQuery),
          invited: false,
        },
      })
      setPage(newPage)
    }

    /**
     * 行選択ハンドラ
     * @param event イベント
     * @param key キー値
     */
    const handleRowClick = (
      _event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
      key: string,
    ) => {
      const newUsers = users.map((u) => {
        if (u.mcdpId === key) {
          return { ...u, selected: !u.selected }
        }

        return u
      })
      setUsers(newUsers)
    }

    /**
     * 全行選択(チェックボックス選択)ハンドラ
     * @param event イベント
     */
    const handleSelectAllClick = (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      const newUsers = users.map((u) => ({
        ...u,
        selected: event.target.checked,
      }))
      setUsers(newUsers)
    }

    /**
     * ユーザー追加
     */
    const invite = useThrottledCallback(
      () => {
        clearPageHeaderErrorMessage()
        doPost({
          prime: props.prime,
          partnerCompanyId: params.partnerCompanyId,
          projectId: params.projectId,
          companyPositionId: params.companyPositionId,
          partnerCompanyUsers: users
            .filter((u) => u.selected)
            .map((u) => {
              return {
                mcdpId: u.mcdpId,
                enabled: true,
                updateDatetime: u.updateDatetime,
              }
            }),
        })
      },
      1000,
      { trailing: false },
    )

    //  戻り先URL（協力会社ユーザ一覧ページ画面)
    const backUrl = props.prime
      ? historyQuery.findByPath(
          `/prime/projects/${params.projectId}/partnerCompanies/${params?.partnerCompanyId}/companyPositions/${params.companyPositionId}/invitedUsers`,
        )
      : historyQuery.findByPath(
          `/partnerCompanies/${params?.partnerCompanyId}/projects/${params.projectId}/companyPositions/${params.companyPositionId}/invitedUsers`,
        )

    /** １度も検索が実行されていない場合、列すら表示しない。 */
    const showWhiteScreen = partnerCompanyUsers.data === undefined

    // エラーメッセージ
    const [errorMessages, setErrorMessages] = React.useState<string[]>([])
    useEffect(() => {
      const messages = []

      // 検索結果なし
      if (partnerCompanyUsers.data && users.length === 0) {
        messages.push(NO_DATA_MESSAGE)
      }

      // APIエラー
      const partnerCompanyUsersError =
        partnerCompanyUsers.error?.errors
          .filter((error) => !error.field)
          .map((error) => error.message)
          .join('') || partnerCompanyUsers.error?.message
      if (partnerCompanyUsersError) {
        messages.push(partnerCompanyUsersError)
      }
      if (partnerCompanyUsersPost.error?.message) {
        messages.push(partnerCompanyUsersPost.error.message)
      }

      setErrorMessages(messages)
    }, [users, partnerCompanyUsers.error, partnerCompanyUsersPost.error])

    return (
      <Template.Container>
        <Template.Body>
          <Template.Header loading={partnerCompanyProject.loading}>
            <div>
              <ContentTitle title={PAGE_TITLE} />
              <ContentSubTitle
                text={`プロジェクト名：${partnerCompanyProject.data?.projectName}`}
              />
              <ContentSubTitle
                text={`元請会社名：${partnerCompanyProject.data?.primeCompanyName}`}
              />
              <ContentSubTitle
                text={`協力会社名：${partnerCompanyProject.data?.companyName}`}
              />
              <ContentSubTitle
                text={`立場：${partnerCompanyProject.data?.companyPositionName}`}
              />
              <ProjectServiceIconsWithCaption
                services={partnerCompanyProject.data?.relatedServices}
                caption="利用サービス："
              />
            </div>
            <InlineContainer>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} alignItems="flex-end">
                  <Grid item xs>
                    <StyledTextField
                      name="userNameKana"
                      label="ユーザーカナ名"
                      inputRef={register}
                      formErrors={errors}
                      fetchErrors={partnerCompanyUsers.error}
                      defaultValue={userNameKanaQuery}
                      className={classes.textField}
                      maxLength={40}
                    />
                    <StyledTextField
                      name="departmentName"
                      label="部署名"
                      inputRef={register}
                      formErrors={errors}
                      fetchErrors={partnerCompanyUsers.error}
                      defaultValue={departmentNameQuery}
                      className={classes.textField}
                      maxLength={150}
                    />
                    <MultiSelectCheckbox
                      label="サービス利用状況"
                      name="primeCompanyServices"
                      inputRef={register}
                      fetchErrors={partnerCompanyUsers.error}
                      fetchName="serviceIds"
                      checkboxes={checkboxes}
                      defaultValue={selectedServiceIds}
                      className={classes.multiSelectCheckbox}
                    />
                  </Grid>
                  <Grid item>
                    <SearchButton />
                  </Grid>
                </Grid>
              </form>
            </InlineContainer>
          </Template.Header>
          {!partnerCompanyProject.loading && (
            <Typography className={classes.description}>
              {
                `元請企業のサービスを１つでも利用設定しているユーザーを追加することができます。
                ユーザーが表示されない場合は、サービス利用設定画面にて設定をお願いします。
                ユーザー追加後、対象のプロジェクトにおいてサービスが利用できるまで約1時間程度かかります。反映していない場合は、時間を置いてご利用ください。`
              }
            </Typography>
          )}

          <Template.ErrorContent>
            <ErrorMessageContainer messages={errorMessages} />
          </Template.ErrorContent>
          <Template.Content
            loading={pageContentLoading}
            hidden={showWhiteScreen || errorMessages.length > 0}
            height={PAGE_CONTENT_HEIGHT}
          >
            <PartnerCompanyUserTable
              users={users}
              services={primeCompanyServices.data}
              handleSelectAllClick={handleSelectAllClick}
              handleRowClick={handleRowClick}
              showCheckBox={true}
            />
          </Template.Content>
        </Template.Body>

        <Template.Footer loading={pageContentLoading}>
          {!showWhiteScreen && !errorMessages.length && (
            <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={partnerCompanyUsers.data?.count || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
            />
          )}
          <FooterButtonContainer>
            <HistoryBackButton color="secondary" url={backUrl} />
            <ConfirmDialogButton
              handler={invite}
              disabled={!users.some((u) => u.selected)}
              name="invitation"
              buttonText="ユーザー追加"
              dialogConfirmText="対象のユーザーをプロジェクトに追加してもよろしいですか？"
              color="secondary"
            />
          </FooterButtonContainer>
        </Template.Footer>
      </Template.Container>
    )
  }
