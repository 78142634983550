import { ErrorResponse, useAxios } from '.'
import { useApiGlobalErrorHandler } from '../hooks'
import { GlobalErrorHandlerOption } from './types'
import { querystring } from './util'

/**
 * プロジェクトステータス
 * '1': 稼働中
 * '2': 凍結
 * '8': 完了
 * '9': 削除
 */
export const ProjectStatus = {
  RUNNING: '1',
  SUSPEND: '2',
  COMPLETE: '8',
  DELETE: '9',
}

/**
 * 元請プロジェクトサービス
 */
type PrimeProjectService = {
  projectServiceId: string
  projectServiceName: string
}

/**
 * プロジェクト情報
 */
type Project = {
  projectId: string
  projectName: string
  primeCompanyId: string
  primeCompanyName: string
  primeBranchId: string
  primeBranchName: string
  companyPositionId: string
  companyPositionName: string
  projectStatus: string
  invitedUserCount: number
  invitedDate: string
  relatedServices: PrimeProjectService[]
}

export type PartnerCompanyProjectsResponse = {
  count: number
  projects: Project[]
}

export type PartnerCompanyProjectsApiParam = {
  partnerCompanyId: string
  limit?: number
  offset?: number
  projectName?: string
  primeCompanyId?: string
  companyPositionId?: string
  sortBy?: string
}

type UsePartnerCompanyProjectsApi = {
  data: PartnerCompanyProjectsResponse | undefined
  error: ErrorResponse | null
  loading: boolean
  doFetch: (params: PartnerCompanyProjectsApiParam) => void
}

/**
 * 協力会社招待プロジェクト提供（複数件）API Hook
 * @param param
 * @param option
 * @returns
 */
export const usePartnerCompanyProjectsApi = (
  param: PartnerCompanyProjectsApiParam,
  option?: GlobalErrorHandlerOption,
): UsePartnerCompanyProjectsApi => {
  const { partnerCompanyId, ...queryParams } = param
  const qs = querystring.stringify(queryParams)
  const { data, error, loading, fetchData } =
    useAxios<PartnerCompanyProjectsResponse>({
      method: 'GET',
      url: `/partnerCompanies/${partnerCompanyId}/projects?${qs}`,
    })

  if (option?.use ?? true) {
    useApiGlobalErrorHandler(error, option)
  }

  const doFetch = (param: PartnerCompanyProjectsApiParam) => {
    const { partnerCompanyId, ...queryParams } = param
    const qs = querystring.stringify(queryParams)
    fetchData({
      method: 'GET',
      url: `/partnerCompanies/${partnerCompanyId}/projects?${qs}`,
    })
  }

  return { data, error, loading, doFetch }
}
