import { ErrorResponse, useAxios } from '.'

type Response = {
  authorizationUrl: string
}

export const useAuthApi = (): {
  data: Response | undefined
  error: ErrorResponse | null
  loading: boolean
} => {
  const { data, error, loading } = useAxios<Response>({
    method: 'GET',
    url: `/auth/login`,
  })

  return { data, error, loading }
}

/** 企業担当者区分（認証基盤） */
export const MANAGER_TYPE = {
  /** 一般ユーザー */
  STAFF: '1',
  /** 企業担当者 */
  COMPANY_MANAGER: '2',
  /** 企業サブ担当者 */
  COMPANY_SUB_MANAGER: '3',
  /** システム管理者 */
  SYSTEM_MASTER: '4',
  /** ヘルプデスク */
  HELP_DESK: '5',
  /** MCDP運用担当 */
  MCDP_USER: '6',
} as const
