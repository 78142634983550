import { ThemeProvider } from '@material-ui/core/styles'

import { Theme } from './Theme'
import { Routes } from './Routes'
import { AuthProvider } from './providers'
import { SnackbarProvider } from 'notistack'

export const App = (): JSX.Element => {
  return (
    <ThemeProvider theme={Theme}>
      <SnackbarProvider maxSnack={3}>
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </SnackbarProvider>
    </ThemeProvider>
  )
}
