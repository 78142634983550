import { ErrorResponse, useAxios } from '.'
import { useApiGlobalErrorHandler } from '../hooks'
import { GlobalErrorHandlerOption } from './types'

export type CompanyPositionsResponse = CompanyPosition

type CompanyPosition = {
  companyPositionId: string
  companyPositionName: string
}[]

type UsePartnerCompanyProjectsApi = {
  data: CompanyPositionsResponse | undefined
  error: ErrorResponse | null
  loading: boolean
  doFetch: () => void
}

/**
 * 企業立場マスタ提供API Hook
 * @param option
 * @returns
 */
export const useCompanyPositionsApi = (
  option?: GlobalErrorHandlerOption,
): UsePartnerCompanyProjectsApi => {
  const { data, error, loading, fetchData } =
    useAxios<CompanyPositionsResponse>({
      method: 'GET',
      url: `/master/companyPositions`,
    })

  if (option?.use ?? true) {
    useApiGlobalErrorHandler(error, option)
  }

  const doFetch = () => {
    fetchData({
      method: 'GET',
      url: `/master/companyPositions`,
    })
  }

  return { data, error, loading, doFetch }
}
