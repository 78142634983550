import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { get } from 'lodash'
import { Unauthorized, Forbidden, NotFound, InternalServer } from '../view/'

export const ErrorHandler: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const location = useLocation()
  const history = useHistory()

  // history.replace に 渡す location.state は画面リロードではリフレッシュされないため、リロード時に強制的に location.state を取り除いている。
  React.useEffect(() => {
    history.replace(history.location.pathname + history.location.search)
  }, [])

  const statusCode = get(location.state, 'errorStatusCode');
  if (statusCode === 401) {
    return (
      <Unauthorized
        showRedirectToLogin={
          get(location.state, 'showRedirectToLogin') ?? false
        }
      />
    )
  } else if (statusCode === 403) {
    return <Forbidden />
  } else if (statusCode === 404) {
    return <NotFound />
  } else if (statusCode === 500) {
    return <InternalServer />
  } else {
    return children
  }
}
