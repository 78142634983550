import { LinkProps } from 'react-router-dom'
import { Button, ButtonProps } from './Button'

type PageHeaderButtonProps = ButtonProps & LinkProps

export const PageHeaderButton: React.FC<PageHeaderButtonProps> = (props) => {
  return (
    <Button variant="contained" size="small" color="secondary" {...props}>
      {props.children}
    </Button>
  )
}
