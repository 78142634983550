// 協力会社招待プロジェクト提供（1件）APIをfetchするHook

import { ErrorResponse, useAxios } from '.'
import { useApiGlobalErrorHandler } from '../hooks'
import { GlobalErrorHandlerOption } from './types'
import { querystring } from './util'

/**
 * 協力会社招待プロジェクト提供（1件）API レスポンス
 */
export type PartnerCompanyProjectResponse = {
  projectName: string
  primeCompanyName: string
  companyName: string
  companyPositionName: string
  projectStatus: string
  relatedServices: PrimeProjectService[]
}

/**
 * 元請プロジェクトサービス
 */
type PrimeProjectService = {
  projectServiceId: string
  projectServiceName: string
}

/**
 * 協力会社招待プロジェクト提供（1件）API リクエストパラメータ
 */
export type PartnerCompanyProjectApiParam = {
  prime: boolean
  partnerCompanyId: string
  projectId: string
  companyPositionId: string
  running?: boolean
}

type UsePartnerCompanyProjectApi = {
  data: PartnerCompanyProjectResponse | undefined
  error: ErrorResponse | null
  loading: boolean
  doFetch: (params: PartnerCompanyProjectApiParam) => void
}

/**
 * 協力会社招待プロジェクト提供（1件）API
 * @param param
 * @param option
 * @returns
 */
export const usePartnerCompanyProjectApi = (
  param: PartnerCompanyProjectApiParam,
  option?: GlobalErrorHandlerOption,
): UsePartnerCompanyProjectApi => {
  const url = getUrl(param)

  const { data, error, loading, fetchData } =
    useAxios<PartnerCompanyProjectResponse>({
      method: 'GET',
      url,
    })

  if (option?.use ?? true) {
    useApiGlobalErrorHandler(error, option)
  }

  const doFetch = (param: PartnerCompanyProjectApiParam) => {
    const url = getUrl(param)
    fetchData({
      method: 'GET',
      url,
    })
  }

  return { data, error, loading, doFetch }
}

const getUrl = (param: PartnerCompanyProjectApiParam) => {
  const {
    prime,
    partnerCompanyId,
    projectId,
    companyPositionId,
    ...queryParams
  } = param

  const qs = querystring.stringify(queryParams)

  return prime
    ? `/prime/projects/${projectId}/partnerCompanies/${partnerCompanyId}/companyPositions/${companyPositionId}?${qs}`
    : `/partnerCompanies/${partnerCompanyId}/projects/${projectId}/companyPositions/${companyPositionId}?${qs}`
}
