import { makeStyles, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import { useTitle } from '../../hooks'
import { StyledButton } from '../../components'

const useStyles = makeStyles(() => ({
  page: {
    textAlign: 'center',
  },
}))

type UnauthorizedProps = {
  showRedirectToLogin: boolean
}

export const Unauthorized: React.FC<UnauthorizedProps> = (props) => {
  useTitle('Unauthorized')
  const classes = useStyles()
  const history = useHistory()

  const { showRedirectToLogin } = props

  const redirectToLogin = () => {
    history.push('/auth/login')
  }

  return (
    <div className={classes.page}>
      <Typography variant="h1">401</Typography>
      <br />
      <Typography variant="h4">Unauthorized</Typography>
      <br />
      <Typography variant="h6">
        認証に失敗したためページを表示できませんでした。
      </Typography>
      {showRedirectToLogin ? (
        <StyledButton onClick={redirectToLogin}>ログイン</StyledButton>
      ) : null}
    </div>
  )
}
