import React, { useCallback, useEffect, useState } from 'react'
import { useMeApi } from '../api'

/**
 * ログインユーザー情報
 */
export type User = {
  mcdpId: string
  lastName: string
  firstName: string
  companyId: string
  companyName: string
  managerType: string
  csrfToken: string
}

export type AuthContextType = {
  user: User | null
  setUser: React.Dispatch<React.SetStateAction<User | null>>
  checkLogin: () => void
  loading: boolean
}

export const AuthContext = React.createContext<AuthContextType>({
  user: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setUser: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  checkLogin: () => {},
  loading: false,
})

const useAuthProvider = () => {
  const [user, setUser] = useState<User | null>(null)
  const { data, loading, doFetch } = useMeApi()

  const checkLogin = useCallback(() => {
    if (!user) doFetch()
  }, [doFetch, user])

  useEffect(() => {
    setUser(data || null)

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {
      setUser(null)
    }
  }, [data])

  return { user, setUser, checkLogin, loading }
}

type Props = {
  children: React.ReactNode;
};

/**
 * ログインユーザーの情報を保持、取得するためのコンテキスト
 * @param props
 */
export const AuthProvider: React.FC<Props> = (props) => {
  const auth = useAuthProvider()

  return (
    <AuthContext.Provider value={auth}>{props.children}</AuthContext.Provider>
  )
}
