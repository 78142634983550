import { makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  page: {
    textAlign: 'center',
  },
}))

export const InternalServer: React.FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.page}>
      <Typography variant="h1">500</Typography>
      <br />
      <Typography variant="h4">Unexpected Error</Typography>
      <br />
      <Typography variant="h6">ページを表示できませんでした。</Typography>
    </div>
  )
}
